import {
  getCoreRowModel,
  useReactTable,
  flexRender,
  getFilteredRowModel,
  getPaginationRowModel,
} from '@tanstack/react-table';
import { Pagination } from './Pagination';
import { IReactTableProps } from 'interface';
import { useEffect, useState } from 'react';
import DialogBox from 'components/Shipment-details-DialogBox/DiallogBox';
import { Modal } from 'components/modal/Modal';
import { loader } from 'assets';
// import { useReactToPrint } from 'react-to-print';
// import { PrintTemplate } from 'utils/ExportFunctions';
// import { Invoice } from 'store/slices/dailySales/dailySalesData';
import { useNavigate } from 'react-router-dom';

export const AgilityTable = <T extends object>({
  data,
  columns,
  className,
  headerClass,
  rowClass,
  dataContClass,
  perPage,
  setPerPage,
  changePage,
  pages,
  dataLength,
  invoicesData,
  isLoading,
  setSortParam,
  setOrderBy,
  paginationHide,
  tableData,
  deleteItem,
  setSerialno,
}: IReactTableProps<T>) => {

  const navigate = useNavigate();
  const [pageNumber, setPageNumber] = useState(1);
  const [shipmentId, setShipmentId] = useState<number | any>(null);
  const [modal, setModal] = useState<{
    status: boolean;
    type: string;
    content?: object;
  }>({ status: false, type: '', content: {} });
  // const [dataToPrint, setDataToPrint] = useState<Invoice | null>(null);
  // const componentRef = useRef<HTMLDivElement | null>(null);
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: true, // We handle pagination ourselves
    pageCount: Math.ceil(!dataLength ? 0 : dataLength / Number(perPage)),
    state: {
      pagination: {
        pageIndex: pageNumber - 1,
        pageSize: Number(perPage),
      },
    },
  });
  
  const handleserialno = () => {
    const newSerialno = table.getState().pagination.pageIndex * table.getState().pagination.pageSize;
    setSerialno?.(newSerialno); 
  };
  const handleChangePageNumber = (PageNumber: number) => {
    setPageNumber(PageNumber);
    changePage && changePage(Number(perPage), PageNumber);
    handleserialno()
  };
  useEffect(() => {
    setSerialno?.(table.getState().pagination.pageIndex * table.getState().pagination.pageSize);
  }, [pageNumber, setSerialno, table]);

  const closeModal = () => {
    setModal({ status: false, type: '', content: {} });
  };

  const openCreateModal = (type: string, content?: object) => {
    setModal({ status: true, type, content });
  };

  const handleViewShipmentDetail = (index: number) => {
    const filteredData = invoicesData && invoicesData[index];
    const shipmentId = filteredData?.ShipmentId;
    setShipmentId(shipmentId);
    openCreateModal('shipment-detail-modal');
  };

  const handleNavigate = (index: number) => {
    // debugger

    const filteredData = invoicesData && invoicesData[index];
    if (!filteredData) return;
    navigate('shipment-invoice', { state: { filteredData } });
    // setDataToPrint(filteredData);
  };

  // const handlePrint = useCallback(
  //   useReactToPrint({
  //     content: () => componentRef.current,
  //     onAfterPrint() {
  //       setDataToPrint(null);
  //     },
  //   }),
  //   [],
  // );

  // useEffect(() => {
  //   if (!dataToPrint) return;
  //   handlePrint();
  // }, [dataToPrint]);


  const handlefilterdata = (id: string) => {
    tableData?.(id);
  };

  const handleDelete = (id: string) => {
    deleteItem?.(id)
  };



  return (
    <div className={`flex flex-col overflow-x-hidden ${className}`}>
      {isLoading ? (
        <img className="mx-auto" src={loader} />
      )
        : (
          <div className="overflow-x-hidden sm:-mx-6 lg:-mx-8">

            {
              data?.length > 0 ?
                <div
                  className={`inline-block w-full overflow-x-hidden sm:px-6 lg:px-8`}
                >
                  <div className="table-overflow-cont overflow-auto pt-2 pb-2 mb-2">


                    <table className="min-w-full text-center">
                      <thead className={`${headerClass}`}>
                        {table?.getHeaderGroups()?.map((headerGroup) => (

                          <tr key={headerGroup.id}>
                            {headerGroup.headers?.map((header) => (
                              <th
                                onClick={() => {
                                  setOrderBy &&
                                    setOrderBy((prev) =>
                                      prev === 'ASC' ? 'DESC' : 'ASC',
                                    );
                                  setSortParam && setSortParam(header?.id);
                                }}
                                key={header.id}
                                className=" px-4 pt-0 pb-2 text-sm font-medium text-primary uppercase text-left pl-6 whitespace-nowrap"
                              >
                                {header.isPlaceholder
                                  ? null
                                  : flexRender(
                                    header.column.columnDef.header,
                                    header.getContext(),
                                  )}
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <tbody
                        className={`rounded-[1rem] py-[2rem] ${dataContClass}`}
                        style={{ borderRadius: '1rem' }}
                      >
                        {table.getRowModel().rows?.map((row,) => (

                          <tr
                            key={row.id}
                            className={`table-body-row py-4 w-[90%] mx-auto mr-4 ml-4 ${rowClass}`}
                          >
                            {/* <td className="table-body-td whitespace-nowrap px-2 py-6 text-sm  text-grey-200 text-left pl-6 last:pr-6">
                              {table.getState().pagination.pageIndex * table.getState().pagination.pageSize + index + 1}.
                            </td> */}
                            {row.getVisibleCells()?.map((cell) => (
                              <td
                                onClick={() =>
                                  cell.column.id === 'viewIcon'
                                    ? handleViewShipmentDetail(Number(row.id))
                                    : cell.column.id === 'printIcon'
                                      ? handleNavigate(Number(row.id))
                                      : cell.column.id === "addItem" ? handlefilterdata(row?.id) : cell.column.id === "deleteItem" ? handleDelete(row?.id,) : null
                                }
                                className="table-body-td whitespace-nowrap px-2 py-6 text-sm font-bold text-grey text-left pl-6 last:pr-6"
                                key={cell.id}
                              >
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext(),
                                )}
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  {paginationHide !== true && <div className="overflow-x-hidden pagintion mt-0">
                    <Pagination
                      handleChangePageNumber={handleChangePageNumber}
                      perPage={perPage}
                      setPerPage={setPerPage}
                      page={pageNumber}
                      changePage={changePage}
                      pages={pages}
                      dataLength={dataLength}
                    />
                  </div>}
                </div>
                :
                <div className="text-center text-lg text-gray-500">No Data Found!</div>}
          </div>
        )}
      {/* {
        <div className="hidden">
          <PrintTemplate ref={componentRef} data={dataToPrint} />
        </div>
      } */}
      <Modal
        modalOpen={modal.status}
        modalClose={closeModal}
        modalPatterBg={true}
        // closeIcon={true}
        modalClass="shipment-detail-modal"
        modalChild={
          modal.type === 'shipment-detail-modal' ? (
            <DialogBox
              shipmentId={shipmentId}
              isOpen={modal.status}
              onClose={closeModal}
            />
          ) : null
        }
        animateModal={true}
      />
    </div>
  );
};
