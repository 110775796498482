import { useState } from 'react';
import { Header } from 'components/header/Header';
// import { white_plus_icon } from 'assets';
import { AgilityTable } from 'components/table/AgilityTable';
import { useTableSetup } from 'hooks/useTablesetup';
import {
  adminOptionIndividualCustomerTableDataSample,
  editors,
} from 'utils/sampleData';
import { Button } from 'components';
import { TextInput } from 'components/inputs/TextInput';
import { MobileNav } from 'components/layout/Layout';
import { CreateIndividualCustomer } from './CreateIndividualCustomer';
import {
  adminIndividualCustomerCreateTableData,
  adminIndividualCustomer_TableHeader,
} from 'components/admin-options/adminOptions.tableHeader';
import { white_plus_icon } from 'assets';

export const IndividualCustomer = () => {
  const [showCreateForm, setShowCreateForm] = useState(false);

  const { cols, tableData } = useTableSetup({
    tableHeader: adminIndividualCustomer_TableHeader,
    createTableRow: adminIndividualCustomerCreateTableData(
      adminOptionIndividualCustomerTableDataSample?.dataList?.map((item) => ({
        ...item,
        others: {
          viewItem: () => setShowCreateForm(true),
        },
      })),
    ),
  });

  const handleClickHeaderBtn = () => {
    setShowCreateForm(true);
  };

  return (
    <div className="page-cont">
      <Header
        greenTimerBtn={false}
        category="home"
        subCategory="Individual Customer"
        headerRedTopBtn={{
          text: 'Create Individual Customer',
          prefixIcon: white_plus_icon,
          onClick: handleClickHeaderBtn,
        }}
      />
      {!showCreateForm ? (
        <main className="slide-up-content-reveal page-main-cont tb:mt-[8.5rem] mt-[12rem] ">
          <div className="buttons-cont pt-3 w-full flex items-center justify-between flex-wrap gap-x-[1rem] ">
            <div className="flex items-center gap-x-2">
              {editors.map((item: any, i: number) => (
                <Button
                  key={i}
                  text={item.title}
                  prefixIcon={item.icon}
                  className="rounded-[0.5rem] px-5 py-3 bg-col_76818e flex items-center text-txt_087_normal text-col_fff whitespace-nowrap"
                />
              ))}
            </div>
            <div className="flex items-center gap-2">
              <TextInput
                type="search"
                placeholder="Search"
                className="w-[15.5rem] flex-grow "
                inputWrapperClass="bg-col_fff pl-3 border"
              />
              <Button
                text="Search"
                className={`rounded-[0.8rem] py-3 px-6 flex items-center justify-center text-txt_087_normal text-col_fff bg-col_e84e40`}
              />
            </div>
          </div>

          <div className="table-cont mt-6">
            <AgilityTable
              data={tableData ? tableData : []}
              columns={cols}
              pages={5}
              rowClick={() => console.log('row clicked')}
              dataContClass="bg-col_fff"
            />
          </div>

          <MobileNav
            headerNaviagtionBtn={[
              {
                text: 'create Individual Customer',
                type: 'red-btn',
                prefixIcon: white_plus_icon,
                onClick: handleClickHeaderBtn,
              },
            ]}
            className=""
          />
        </main>
      ) : (
        <CreateIndividualCustomer
          toggleCreateView={() => setShowCreateForm(false)}
        />
      )}
    </div>
  );
};
