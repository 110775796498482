
type Props = {
    title: string
    subTitle?: string
    className?: string
    subtitleClass?: string
    prefixIcon?: string
}

export const RedTitle = ({title, subTitle, className, subtitleClass, prefixIcon}: Props) => {
  return (
    <div className={`red-header w-full flex items-center justify-between ${className}`}>
     <div className="flex items-center gap-2">
     {prefixIcon && <img src={prefixIcon} alt="" />}
      <span>{title}</span>
     </div>
      <span className={`justify-self-end ${subtitleClass}`}>{subTitle}</span>
    </div>
  )
}