import { copy, excel, pdf, print_white_icon } from 'assets';

export const editors = [
  { title: 'Copy', icon: copy },
  { title: 'Print', icon: print_white_icon },
  { title: 'Excel', icon: excel },
  { title: 'PDF File', icon: pdf },
];

export const tableRow = [
  { no: 'i', name: '12kg', nature: 'normal', weight: '2kg' },
  { no: 'ii', name: '24kg', nature: 'special', weight: '24kg' },
  { no: 'iii', name: '9kg', nature: 'normal', weight: '2kg' },
];

export const dailySalesTableSampleData = {
  perPage: '',
  pages: '',
  totalData: '',
  dataList: [
    {
      waybill: '74jr648eh6',
      destination: 'itoku, abeokuta',
      grandTotal: 34567.37,
      weight: 33,
      status: 'paid',
      paymentType: 'cash',
      deliveryType: 'standard',
      cash: 9090,
    },
    {
      waybill: '74jr648eh6',
      destination: 'itoku, abeokuta',
      grandTotal: 34567,
      weight: 33,
      status: 'pending',
      paymentType: 'cash',
      deliveryType: 'standard',
      cash: 9090,
    },
    {
      waybill: '74jr648eh6',
      destination: 'itoku, abeokuta',
      grandTotal: 34567.0,
      weight: 33,
      status: 'paid',
      paymentType: 'cash',
      deliveryType: 'standard',
      cash: 9090,
    },
    {
      waybill: '74jr648eh6',
      destination: 'itoku, abeokuta',
      grandTotal: 34567,
      weight: 33,
      status: 'pending',
      paymentType: 'cash',
      deliveryType: 'standard',
      cash: 9090,
    },
    {
      waybill: '74jr648eh6',
      destination: 'itoku, abeokuta',
      grandTotal: 34567.0,
      weight: 33,
      status: 'paid',
      paymentType: 'cash',
      deliveryType: 'standard',
      cash: 9090,
    },
    {
      waybill: '74jr648eh6',
      destination: 'itoku, abeokuta',
      grandTotal: 34567,
      weight: 33,
      status: 'pending',
      paymentType: 'cash',
      deliveryType: 'standard',
      cash: 9090,
    },
    // {waybill: "74jr648eh6", destination: "itoku, abeokuta", grandTotal: 34567.00, weight: 33, status: "paid", paymentType: "cash", deliveryType: "standard", cash: 9090},
    // {waybill: "74jr648eh6", destination: "itoku, abeokuta", grandTotal: 34567, weight: 33, status: "pending", paymentType: "cash", deliveryType: "standard", cash: 9090},
    // {waybill: "74jr648eh6", destination: "itoku, abeokuta", grandTotal: 34567.00, weight: 33, status: "paid", paymentType: "cash", deliveryType: "standard", cash: 9090},
    // {waybill: "74jr648eh6", destination: "sango", grandTotal: 34567, weight: 33, status: "pending", paymentType: "cash", deliveryType: "standard", cash: 9090},
    // {waybill: "74jr648eh6", destination: "Lekki", grandTotal: 34567.00, weight: 33, status: "paid", paymentType: "cash", deliveryType: "standard", cash: 9090},
    // {waybill: "74jr648eh6", destination: "itoku, Iwaya", grandTotal: 34567, weight: 33, status: "pending", paymentType: "cash", deliveryType: "standard", cash: 9090},
    // {waybill: "74jr648eh6", destination: "itoku, abeokuta", grandTotal: 34567.00, weight: 33, status: "paid", paymentType: "cash", deliveryType: "standard", cash: 9090},
    // {waybill: "74jr648eh6", destination: "itoku, abeokuta", grandTotal: 34567, weight: 33, status: "pending", paymentType: "cash", deliveryType: "standard", cash: 9090},
    // {waybill: "74jr648eh6", destination: "itoku, abeokuta", grandTotal: 34567.00, weight: 33, status: "paid", paymentType: "cash", deliveryType: "standard", cash: 9090},
    // {waybill: "74jr648eh6", destination: "itoku, abeokuta", grandTotal: 34567, weight: 33, status: "pending", paymentType: "cash", deliveryType: "standard", cash: 9090},
    // {waybill: "74jr648eh6", destination: "itoku, abeokuta", grandTotal: 34567.00, weight: 33, status: "paid", paymentType: "cash", deliveryType: "standard", cash: 9090},
    // {waybill: "74jr648eh6", destination: "itoku, abeokuta", grandTotal: 34567, weight: 33, status: "pending", paymentType: "cash", deliveryType: "standard", cash: 9090},
    // {waybill: "74jr648eh6", destination: "dutse", grandTotal: 34567.00, weight: 33, status: "paid", paymentType: "cash", deliveryType: "standard", cash: 9090},
    // {waybill: "74jr648eh6", destination: "Gombe", grandTotal: 34567, weight: 33, status: "pending", paymentType: "cash", deliveryType: "standard", cash: 9090},
    // {waybill: "74jr648eh6", destination: "Zamfara", grandTotal: 34567.00, weight: 33, status: "paid", paymentType: "cash", deliveryType: "standard", cash: 9090},
    // {waybill: "74jr648eh6", destination: "itoku, abeokuta", grandTotal: 34567, weight: 33, status: "pending", paymentType: "cash", deliveryType: "standard", cash: 9090},
    // {waybill: "74jr648eh6", destination: "itoku, abeokuta", grandTotal: 34567.00, weight: 33, status: "paid", paymentType: "cash", deliveryType: "standard", cash: 9090},
    // {waybill: "74jr648eh6", destination: "sango", grandTotal: 34567, weight: 33, status: "pending", paymentType: "cash", deliveryType: "standard", cash: 9090},
    // {waybill: "74jr648eh6", destination: "sango", grandTotal: 34567, weight: 33, status: "pending", paymentType: "cash", deliveryType: "standard", cash: 9090},
  ],
};

export const deliveryLocationsTableSampleData = {
  perPage: '',
  pages: '',
  totalData: '',
  dataList: [
    { location: 'agege', homeDeliveryService: 'available' },
    { location: 'ikotun', homeDeliveryService: 'not available' },
  ],
};
export const packingListTableSampleData = {
  perPage: '',
  pages: '',
  totalData: '',
  dataList: [{ waybill: '2juh474y4h4y4' }, { waybill: '74n4uy4h4hgr' }],
};

export const overdueShipmentTableDataSample = {
  perPage: '',
  pages: '',
  totalData: '',
  dataList: [
    {
      waybill: '74jr648eh6',
      dateCreated: 'jun 26, 2020',
      amount: 34567.37,
      overdueCount: '-',
      discount: 0,
    },
  ],
};

export const expenditureTableDataSample = {
  perPage: '',
  pages: '',
  totalData: '',
  dataList: [
    {
      waybill: '74jr648eh6',
      dateCreated: 'jun 26, 2020',
      amount: 34567.37,
      overdueCount: '-',
      discount: 0,
    },
  ],
};

export const ecomsOverdueShipmentTableDataSample = {
  perPage: '',
  pages: '',
  totalData: '',
  dataList: [
    {
      waybill: '74jr648eh6',
      dateCreated: 'jun 26, 2020',
      departureCenter: 'enugu, rivers state',
      destinationCenter: 'Surulere (Ogunlana Drive)',
      approveShipmentReturn: 'approve shipment return',
    },
    {
      waybill: '74jr648eh6',
      dateCreated: 'jun 26, 2020',
      departureCenter: 'enugu, rivers state',
      destinationCenter: 'Surulere (Ogunlana Drive)',
      approveShipmentReturn: 'approve shipment return',
    },
    {
      waybill: '74jr648eh6',
      dateCreated: 'jun 26, 2020',
      departureCenter: 'enugu, rivers state',
      destinationCenter: 'Surulere (Ogunlana Drive)',
      approveShipmentReturn: 'approve shipment return',
    },
    {
      waybill: '74jr648eh6',
      dateCreated: 'jun 26, 2020',
      departureCenter: 'enugu, rivers state',
      destinationCenter: 'Surulere (Ogunlana Drive)',
      approveShipmentReturn: 'approve shipment return',
    },
    {
      waybill: '74jr648eh6',
      dateCreated: 'jun 26, 2020',
      departureCenter: 'enugu, rivers state',
      destinationCenter: 'Surulere (Ogunlana Drive)',
      approveShipmentReturn: 'approve shipment return',
    },
    {
      waybill: '74jr648eh6',
      dateCreated: 'jun 26, 2020',
      departureCenter: 'enugu, rivers state',
      destinationCenter: 'Surulere (Ogunlana Drive)',
      approveShipmentReturn: 'approve shipment return',
    },
    {
      waybill: '74jr648eh6',
      dateCreated: 'jun 26, 2020',
      departureCenter: 'enugu, rivers state',
      destinationCenter: 'Surulere (Ogunlana Drive)',
      approveShipmentReturn: 'approve shipment return',
    },
    {
      waybill: '74jr648eh6',
      dateCreated: 'jun 26, 2020',
      departureCenter: 'enugu, rivers state',
      destinationCenter: 'Surulere (Ogunlana Drive)',
      approveShipmentReturn: 'approve shipment return',
    },
  ],
};

export const bankProcessingOrderTableDataSample = {
  perPage: '',
  pages: '',
  totalData: '',
  dataList: [
    { waybill: '74jr648eh6', amount: '9872020', status: 'pending' },
    { waybill: '74jr648eh6', amount: '9872020', status: 'pending' },
    // {waybill: "74jr648eh6", amount: "9872020", status: "pending"},
    // {waybill: "74jr648eh6", amount: "9872020", status: "pending"},
    // {waybill: "74jr648eh6", amount: "9872020", status: "pending"},
    // {waybill: "74jr648eh6", amount: "9872020", status: "pending"},
  ],
};

export const bankDepositOrderTableDataSample = {
  perPage: '',
  pages: '',
  totalData: '',
  dataList: [
    {
      reference: '74jr648eh6',
      dateCreated: 'jun 26, 2020',
      staffName: 'ujuamaka garba',
      serviceCenter: 'Surulere (Ogunlana Drive)',
      bank: 'steallas bank',
      status: 'deposited',
      depositDate: 'July 12, 1997',
    },
    {
      reference: '74jr648eh6',
      dateCreated: 'jun 26, 2020',
      staffName: 'ujuamaka garba',
      serviceCenter: 'Surulere (Ogunlana Drive)',
      bank: 'zenith bank',
      status: 'pending',
      depositDate: 'July 12, 1997',
    },
  ],
};

export const transferManagementDataSample = {
  perPage: '',
  pages: '',
  totalData: '',
  dataList: [
    {
      reference: '74jr648eh6',
      senderAcc: '0909090909',
      amount: '23456',
      senderName: 'gabriel benson',
      senderBank: 'steallas bank',
      status: 'success',
      serviceCenter: 'ikorodu, mainland',
    },
  ],
};

export const groupShipmentViewDataSample = {
  perPage: '',
  pages: '',
  totalData: '',
  dataList: [
    {
      waybill: '74jr648eh6',
      departure: 'abeokuta',
      destinationCenter: 'Gbagada Express Center',
      deliveryType: 'go standard',
      shipmentType: 'bulky',
      date: 'Jun 26, 2022 5:21:22PM',
    },
    {
      waybill: '74jr648eh6',
      departure: 'abeokuta',
      destinationCenter: 'Gbagada Express Center',
      deliveryType: 'go faster',
      shipmentType: 'normal',
      date: 'Jun 26, 2022 5:21:22PM',
    },
    {
      waybill: '74jr648eh6',
      departure: 'abeokuta',
      destinationCenter: 'Gbagada Express Center',
      deliveryType: 'go standard',
      shipmentType: 'normal',
      date: 'Jun 26, 2022 5:21:22PM',
    },
  ],
};

export const HubManifestViewDataSample = [
  {
    manifest: '74jr648eh6',
    departure: 'Ajah Hub',
    destination: 'Gbagada Express Center',
    manifestType: 'external',
    dateMapped: 'Jun 26, 2022 5:21:22PM',
    isDispatched: true,
    dispatcher: 'Pilot Titus',
    isReceived: false,
    index: 1,
  },
  {
    manifest: '74jr648eh6',
    departure: 'Ajah Hub',
    destination: 'Gbagada Express Center',
    manifestType: 'external',
    dateMapped: 'Jun 26, 2022 5:21:22PM',
    isDispatched: true,
    dispatcher: 'Pilot Titus',
    isReceived: false,
    index: 2,
  },
  {
    manifest: '74jr648eh6',
    departure: 'Ajah Hub',
    destination: 'Gbagada Express Center',
    manifestType: 'external',
    dateMapped: 'Jun 26, 2022 5:21:22PM',
    isDispatched: false,
    dispatcher: 'Pilot Titus',
    isReceived: true,
    index: 3,
  },
  {
    manifest: '74jr648eh6',
    departure: 'Ajah Hub',
    destination: 'Gbagada Express Center',
    manifestType: 'external',
    dateMapped: 'Jun 26, 2022 5:21:22PM',
    isDispatched: true,
    dispatcher: 'Pilot Titus',
    isReceived: false,
    index: 4,
  },
];

export const ShipmentDeliveryReportHubSampleData = [
  {
    index: 1,
    waybill: 'gig0iojkr',
    status: 'processing',
    grandTotal: 4555005,
    dateCreated: 'Jun 26, 2022 5:21:22PM',
  },
  {
    index: 2,
    waybill: 'gig0iojkr',
    status: 'processing',
    grandTotal: 4555005,
    dateCreated: 'Jun 26, 2022 5:21:22PM',
  },
  {
    index: 3,
    waybill: 'gig0iojkr',
    status: 'processing',
    grandTotal: 4555005,
    dateCreated: 'Jun 26, 2022 5:21:22PM',
  },
  {
    index: 4,
    waybill: 'gig0iojkr',
    status: 'processing',
    grandTotal: 4555005,
    dateCreated: 'Jun 26, 2022 5:21:22PM',
  },
  {
    index: 5,
    waybill: 'gig0iojkr',
    status: 'processing',
    grandTotal: 4555005,
    dateCreated: 'Jun 26, 2022 5:21:22PM',
  },
];

export const HubManifestAssignementSampleData = [
  {
    index: 1,
    waybill: 'gig0iojkr',
    dateArrived: 'Jun 26, 2022 5:21:22PM',
  },
  {
    index: 2,
    waybill: 'gig0iojkr',
    dateArrived: 'Jun 26, 2022 5:21:22PM',
  },
  {
    index: 3,
    waybill: 'gig0iojkr',
    dateArrived: 'Jun 26, 2022 5:21:22PM',
  },
];

export const adminOptionCountryTableDataSample = {
  perPage: '',
  pages: '',
  totalData: '',
  dataList: [
    {
      name: 'Guubs Ltd',
      code: 'ECO0011234',
      currencyRatio: '0',
      currencyCode: 'NGN',
      currencySymbol: '#',
      phoneNumberCode: '+234',
      contactNumber: '2348100305633',
    },
    // {
    //   name: 'Mapia Global Services',
    //   code: 'ECO0011235',
    //   currencyRatio: '0',
    //   currencyCode: 'NGN',
    //   currencySymbol: '#',
    //   phoneNumberCode: '+233',
    //   contactNumber: '2348100305611',
    // },
  ],
};

export const adminOptionRegionTableDataSample = {
  perPage: '',
  pages: '',
  totalData: '',
  dataList: [
    {
      code: '',
      name: '',
    },
    // {
    //   code: '20020403234',
    //   name: 'Abuja',
    // },
    // {
    //   code: '20020403235',
    //   name: 'Lagos',
    // },
  ],
};

export const adminOptionZoneTableDataSample = {
  perPage: '',
  pages: '',
  totalData: '',
  dataList: [
    {
      code: '',
      name: '',
      status: '',
    },
    // {
    //   code: '20020403234',
    //   name: 'Abuja',
    //   status: 'Active',
    // },
    // {
    //   code: '20020403235',
    //   name: 'Lagos',
    //   status: 'Active',
    // },
  ],
};

export const adminOptionStationTableDataSample = {
  perPage: '',
  pages: '',
  totalData: '',
  dataList: [
    {
      code: '',
      name: '',
      status: '',
      superServiceCenter: '',
      StationPickupPrice: '',
    },
    // {
    //   code: '20020403234',
    //   name: 'Abuja',
    //   status: 'Active',
    //   superServiceCenter: '',
    //   StationPickupPrice: '',
    // },
    // {
    //   code: '20020403235',
    //   name: 'Lagos',
    //   status: 'Active',
    //   superServiceCenter: '',
    //   StationPickupPrice: '',
    // },
  ],
};

export const adminOptionServiceCentreTableDataSample = {
  perPage: '',
  pages: '',
  totalData: '',
  dataList: [
    {
      serviceCentreCode: '20020403233',
      serviceCentreName: 'Abeokuta',
      status: 'Active',
      stationName: '',
      stationAmount: '',
      targetAmount: '',
      targetOrder: '',
      isHub: '',
      isGateway: '',
    },
    // {
    //   serviceCentreCode: '20020403234',
    //   serviceCentreName: 'Abuja',
    //   status: 'Active',
    //   stationName: '',
    //   stationAmount: '',
    //   targetAmount: '',
    //   targetOrder: '',
    //   isHub: '',
    //   isGateway: '',
    // },
    // {
    //   serviceCentreCode: '20020403235',
    //   serviceCentreName: 'Lagos',
    //   status: 'Active',
    //   stationName: '',
    //   stationAmount: '',
    //   targetAmount: '',
    //   targetOrder: '',
    //   isHub: '',
    //   isGateway: '',
    // },
  ],
};

export const adminOptionStoreTableDataSample = {
  perPage: '',
  pages: '',
  totalData: '',
  dataList: [
    {
      name: '',
      address: '',
      city: '',
      state: '',
    },
    // {
    //   name: 'GBA',
    //   address: '10 oshodi apapa express way',
    //   city: 'Gbagada',
    //   state: 'Lagos',
    // },
    // {
    //   name: 'WUS',
    //   address: 'Wuse estate, block 4A',
    //   city: 'Wuse',
    //   state: 'Abuja',
    // },
  ],
};

export const adminOptionShipmentLocationTableDataSample = {
  perPage: '',
  pages: '',
  totalData: '',
  dataList: [
    {
      location: '',
      state: '',
      status: '',
      homeDeliveryStatus: '',
    },
    // {
    //   location: 'GBA',
    //   state: 'Lagos',
    //   status: 'Disabled',
    //   homeDeliveryStatus: 'Active',
    // },
    // {
    //   location: 'WUS',
    //   state: 'Abuja',
    //   status: 'Disabled',
    //   homeDeliveryStatus: 'Disabled',
    // },
  ],
};

export const adminOptionPlaceLocationTableDataSample = {
  perPage: '',
  pages: '',
  totalData: '',
  dataList: [
    {
      placeLocationName: '',
      stationName: '',
      state: '',
      isHomeDelivery: '',
      isNormalHD: '',
      isExpressHD: '',
      isExtraMileDelivery: '',
      isGIGGO: '',
      isGateway: '',
    },
  ],
};

export const adminOptionBankTableDataSample = {
  perPage: '',
  pages: '',
  totalData: '',
  dataList: [
    {
      bankName: 'Fidelity Bank',
    },
    {
      bankName: 'Sterling Bank',
    },
    // {
    //   bankName: 'First Bank',
    // },
  ],
};

export const adminOptionRouteZoneMappingTableDataSample = {
  perPage: '',
  pages: '',
  totalData: '',
  dataList: [
    {
      departureStation: '',
      destinationStation: '',
      zone: '',
      status: '',
      eta: '',
    },
    // {
    //   departureStation: 'Jos',
    //   destinationStation: 'Abuja',
    //   zone: '',
    //   status: 'Active',
    //   eta: '',
    // },
    // {
    //   departureStation: 'kaduna',
    //   destinationStation: 'Lagos',
    //   zone: '',
    //   status: 'Active',
    //   eta: '',
    // },
  ],
};

export const adminOptionCountryRouteZoneMappingTableDataSample = {
  perPage: '',
  pages: '',
  totalData: '',
  dataList: [
    {
      departureStation: '',
      destinationStation: '',
      zone: '',
      rate: '',
      type: '',
      status: '',
    },
    // {
    //   departureStation: 'Jos',
    //   destinationStation: 'Abuja',
    //   zone: '',
    //   rate: '',
    //   type: '',
    //   status: 'Active',
    // },
    // {
    //   departureStation: 'kaduna',
    //   destinationStation: 'Lagos',
    //   zone: '',
    //   rate: '',
    //   type: '',
    //   status: 'Active',
    // },
  ],
};

export const adminOptionDeliveryOptionTableDataSample = {
  perPage: '',
  pages: '',
  totalData: '',
  dataList: [
    {
      code: '',
      description: '',
      type: '',
      status: '',
    },
    // {
    //   code: 'Jos',
    //   description: '',
    //   type: '',
    //   status: 'Active',
    // },
    // {
    //   code: 'kaduna',
    //   description: '',
    //   type: '',
    //   status: 'Active',
    // },
  ],
};

export const adminOptionDeliveryOptionPricesTableDataSample = {
  perPage: '',
  pages: '',
  totalData: '',
  dataList: [
    {
      deliveryOption: '',
      description: '',
      zone: '',
      prie: '',
      country: '',
    },
    // {
    //   deliveryOption: 'Jos',
    //   description: '',
    //   zone: '',
    //   prie: '',
    //   country: '',
    // },
    // {
    //   deliveryOption: 'Lagos',
    //   description: '',
    //   zone: '',
    //   prie: '',
    //   country: '',
    // },
  ],
};

export const adminOptionDeliveryLocationPricesTableDataSample = {
  perPage: '',
  pages: '',
  totalData: '',
  dataList: [
    {
      location: 'Abeokuta',
      price: '#40,000',
    },
    // {
    //   location: 'Lagos',
    //   price: '#700,000',
    // },
    // {
    //   location: 'Kano',
    //   price: '#11,000',
    // },
  ],
};

export const adminOptionRegionSCmappingTableDataSample = {
  perPage: '',
  pages: '',
  totalData: '',
  dataList: [
    {
      regionName: 'Aba Area',
      serviceCentres: `ABA (5 Asa Road), ABA 2 (30 BRASS ROAD ABA), CALABAR (29 Marian road beside former Mr fans)`,
    },
    // {
    //   regionName: 'Ajah Area',
    //   serviceCentres: `ABA (5 Asa Road), ABA 2 (30 BRASS ROAD ABA), CALABAR (29 Marian road beside former Mr fans)`,
    // },
    // {
    //   regionName: 'Lekki Area',
    //   serviceCentres: `ABA (5 Asa Road), ABA 2 (30 BRASS ROAD ABA), CALABAR (29 Marian road beside former Mr fans)`,
    // },
  ],
};

export const adminOptionSpecialDeliveryTableDataSample = {
  perPage: '',
  pages: '',
  totalData: '',
  dataList: [
    {
      name: '',
      status: 'Active',
      weight: '54kg',
      type: '',
    },
    // {
    //   name: 'Abeokuta',
    //   status: 'Active',
    //   weight: '20kg',
    //   type: '',
    // },
    // {
    //   name: 'Abeokuta',
    //   status: 'Disabled',
    //   weight: '45kg',
    //   type: '',
    // },
  ],
};

export const adminOptionSpecialZonePricesTableDataSample = {
  perPage: '',
  pages: '',
  totalData: '',
  dataList: [
    {
      specialPackageName: '',
      weight: '54kg',
      zoneName: '',
      price: '',
      country: '',
    },
    // {
    //   specialPackageName: 'Jos',
    //   weight: '20kg',
    //   zoneName: '',
    //   price: '',
    //   country: '',
    // },
    // {
    //   specialPackageName: 'Lagos',
    //   weight: '45kg',
    //   zoneName: '',
    //   price: '',
    //   country: '',
    // },
  ],
};

export const adminOptionRegularZonePricesTableDataSample = {
  perPage: '',
  pages: '',
  totalData: '',
  dataList: [
    {
      weight: '54kg',
      zoneName: '',
      price: '',
      country: '',
      type: '',
    },
    // {
    //   weight: '20kg',
    //   zoneName: '',
    //   price: '',
    //   country: '',
    //   type: 'Jos',
    // },
    // {
    //   weight: '45kg',
    //   zoneName: '',
    //   price: '',
    //   country: '',
    //   type: 'Lagos',
    // },
  ],
};

export const adminOptionPriceCategoryTableDataSample = {
  perPage: '',
  pages: '',
  totalData: '',
  dataList: [
    {
      name: '',
      categoryMinWeight: '',
      categoryMinPrice: '',
      subMinWeight: '',
      subMinPrice: '',
      pricePerWeight: '',
      country: '',
      departureCountry: '',
    },
    // {
    //   name: 'Aba',
    //   categoryMinWeight: '',
    //   categoryMinPrice: '',
    //   subMinWeight: '',
    //   subMinPrice: '',
    //   pricePerWeight: '',
    //   country: '',
    //   departureCountry: '',
    // },
    // {
    //   name: 'Abuja',
    //   categoryMinWeight: '',
    //   categoryMinPrice: '',
    //   subMinWeight: '',
    //   subMinPrice: '',
    //   pricePerWeight: '',
    //   country: '',
    //   departureCountry: '',
    // },
  ],
};

export const adminOptionHaulageTableDataSample = {
  perPage: '',
  pages: '',
  totalData: '',
  dataList: [
    {
      ton: '16T',
      status: 'Active',
      fixedRate: '',
      additionalRate: '',
    },
    // {
    //   tons: '',
    //   status: 'Active',
    //   fixedRate: '',
    //   additionalRate: '',
    // },
    // {
    //   tons: '',
    //   status: 'Inactive',
    //   fixedRate: '',
    //   additionalRate: '',
    // },
  ],
};

export const adminOptionHaulageDistanceTableDataSample = {
  perPage: '',
  pages: '',
  totalData: '',
  dataList: [
    {
      distance: '',
      departure: '',
      destination: 'Abuja',
      status: 'Active',
    },
    // {
    //   distance: '',
    //   departure: '',
    //   destination: 'Abeokuta',
    //   status: 'Inactive',
    // },
    // {
    //   distance: '',
    //   departure: '',
    //   destination: 'Jos',
    //   status: 'Active',
    // },
  ],
};

export const adminOptionHaulagePriceTableDataSample = {
  perPage: '',
  pages: '',
  totalData: '',
  dataList: [
    {
      name: '',
      value: '#20,000',
      status: 'Active',
      country: 'Nigeria',
    },
    // {
    //   name: '54kg',
    //   value: '#6,000',
    //   status: 'Active',
    //   country: 'Nigeria',
    // },
    // {
    //   name: '',
    //   value: '#75,000',
    //   status: 'Active',
    //   country: 'Ghana',
    // },
  ],
};

export const adminOptionVATTableDataSample = {
  perPage: '',
  pages: '',
  totalData: '',
  dataList: [
    {
      name: '',
      value: '#200.50',
      status: 'Active',
      country: 'Nigeria',
    },
    // {
    //   name: '54kg',
    //   value: '#60.00',
    //   status: 'Active',
    //   country: 'Nigeria',
    // },
    // {
    //   name: '',
    //   value: '#70.05',
    //   status: 'Active',
    //   country: 'Ghana',
    // },
  ],
};

export const adminOptionInsuranceTableDataSample = {
  perPage: '',
  pages: '',
  totalData: '',
  dataList: [
    {
      name: '',
      value: '#200.50',
      status: 'Active',
      country: 'Nigeria',
    },
    // {
    //   name: '54kg',
    //   value: '#60.00',
    //   status: 'Active',
    //   country: 'Nigeria',
    // },
    // {
    //   name: '',
    //   value: '#70.05',
    //   status: 'Active',
    //   country: 'Ghana',
    // },
  ],
};

export const adminOptionShipmentPackagePriceTableDataSample = {
  perPage: '',
  pages: '',
  totalData: '',
  dataList: [
    {
      description: '',
      price: '',
      country: 'Nigeria',
    },
    // {
    //   description: '',
    //   price: '',
    //   country: 'Kenya',
    // },
  ],
};

export const adminOptionWeightLimitTableDataSample = {
  perPage: '',
  pages: '',
  totalData: '',
  dataList: [
    {
      weightLimit: '',
      status: 'Active',
    },
    // {
    //   weightLimit: '',
    //   status: 'Active',
    // },
    // {
    //   weightLimit: '',
    //   status: 'Active',
    // },
  ],
};

export const adminOptionWeightLimitPricesTableDataSample = {
  perPage: '',
  pages: '',
  totalData: '',
  dataList: [
    {
      additionalWeight: '',
      zone: '',
      price: '',
      type: '',
      country: 'Nigeria',
    },
    // {
    //   additionalWeight: '',
    //   zone: '',
    //   price: '',
    //   type: '',
    //   country: 'Ghana',
    // },
  ],
};

export const adminOptionGlobalPropertyTableDataSample = {
  perPage: '',
  pages: '',
  totalData: '',
  dataList: [
    {
      key: '',
      value: '',
      description: '',
      country: '',
      status: '',
    },
  ],
};

export const adminOptionExpenseTypeTableDataSample = {
  perPage: '',
  pages: '',
  totalData: '',
  dataList: [
    {
      name: '',
    },
  ],
};

export const adminOptionPartnersTableDataSample = {
  perPage: '',
  pages: '',
  totalData: '',
  dataList: [
    {
      partnerType: '',
      companyName: '',
      code: '',
      email: '',
      phoneNumber: '',
    },
  ],
};

export const adminOptionFleetMakeTableDataSample = {
  perPage: '',
  pages: '',
  totalData: '',
  dataList: [
    {
      name: '',
    },
  ],
};

export const adminOptionFleetModelTableDataSample = {
  perPage: '',
  pages: '',
  totalData: '',
  dataList: [
    {
      makeName: '',
      modelName: '',
    },
  ],
};

export const adminOptionWFleetsTableDataSample = {
  perPage: '',
  pages: '',
  totalData: '',
  dataList: [
    {
      registrationNumber: '',
      model: '',
      type: '',
      capacity: '',
      status: '',
    },
  ],
};

export const adminOptionIndividualCustomerTableDataSample = {
  perPage: '',
  pages: '',
  totalData: '',
  dataList: [
    {
      code: '',
      fullName: '',
      phoneNumber: '',
      email: '',
      gender: '',
      userCountry: '',
    },
  ],
};

export const adminOptionAccountCustomerTableDataSample = {
  perPage: '',
  pages: '',
  totalData: '',
  dataList: [
    {
      code: 'ECO0011234',
      merchantName: 'Mapia Global Services',
      phoneNumber: '+2348100305633',
      email: 'lydiajames@gmail.com',
      type: 'Basic',
      status: 'Active',
      discount: '0',
      settlementPeriod: '0',
      balance: '#76,000.00',
      address: 'No 3. Johnson Street Ikeja',
      city: 'Ikeja',
      state: 'Lagos',
      returnOption: 'Home delivery',
      returnServiceCentre: 'Abuja gateway',
      returnAddress: 'No 3. Johnson Street Ikeja',
      country: 'Nigeria',
      date: 'Jun 26, 2022',
      RCOD: 'True',
    },
  ],
};

export const adminOptionDevicesTableDataSample = {
  perPage: '',
  pages: '',
  totalData: '',
  dataList: [
    {
      name: 'Mapia Global Services',
      serialNumber: 'ECO0011234',
      imei: '864780030412235',
      imeiTwo: '864780030412243',
      handStarp: 'Yes',
      useCable: 'Yes',
      powerAdapter: 'Yes',
      active: 'Active',
      simCardNumber: '',
      networkProvider: '',
      description: '',
    },
  ],
};

export const adminOptionDeviceManagementTableDataSample = {
  perPage: '',
  pages: '',
  totalData: '',
  dataList: [
    {
      device: '',
      serialNumber: '',
      name: '',
      email: '',
      phoneNumber: '',
      designation: 'Basic',
      location: 'Active',
      dataSimcardNumber: '',
      status: 'Active',
    },
  ],
};

export const adminOptionCaptainBonusByZoneMappingTableDataSample = {
  perPage: '',
  pages: '',
  totalData: '',
  dataList: [
    {
      zone: '',
      bonusAccount: '',
    },
  ],
};

export const adminOptionScanStatusTableDataSample = {
  perPage: '',
  pages: '',
  totalData: '',
  dataList: [
    {
      incident: '',
      code: '',
      scanStatus: '',
      comment: '',
      hiddenFlag: '',
    },
  ],
};

export const adminOptionAuditTrailLogsTableDataSample = {
  perPage: '',
  pages: '',
  totalData: '',
  dataList: [
    {
      user: '',
      email: '',
      role: '',
      page: '',
      action: '',
      description: '',
      date: '',
    },
  ],
};

export const adminOptionPaymentMethodTableDataSample = {
  perPage: '',
  pages: '',
  totalData: '',
  dataList: [
    {
      name: '',
      country: '',
      status: 'Active',
    },
  ],
};

export const adminOptionCaptainTransactionsTableDataSample = {
  perPage: '',
  pages: '',
  totalData: '',
  dataList: [
    {
      partnerName: '',
      code: '',
      email: '',
      partnerType: '',
      amount: '',
    },
  ],
};

export const adminOptionGofasterReportTableDataSample = {
  perPage: '',
  pages: '',
  totalData: '',
  dataList: [
    {
      departure: '',
      amount: '',
      totalWeight: '',
      totalShipment: '',
    },
  ],
};

export const adminOptionWalletTableDataSample = {
  perPage: '',
  pages: '',
  totalData: '',
  dataList: [
    {
      name: '',
      email: '',
      phoneNumber: '',
      balance: 'Go Standard',
      type: 'Normal',
      code: '',
      country: '',
    },
  ],
};

export const adminOptionEnterprisePartnersTableDataSample = {
  perPage: '',
  pages: '',
  totalData: '',
  dataList: [
    {
      name: '',
      code: '',
      phoneNumber: '',
    },
  ],
};

export const adminOptionEnterpriseUnassignedPartnersTableDataSample = {
  perPage: '',
  pages: '',
  totalData: '',
  dataList: [
    {
      partnerName: 'Ade James',
      code: 'P0000122',
    },
    {
      partnerName: 'Mark Hughes',
      code: 'P0000120',
    },
    {
      partnerName: 'Declan Rice',
      code: 'P0000121',
    },
  ],
};

export const adminOptioEnterprisePartnersAssignedTableDataSample = {
  perPage: '',
  pages: '',
  totalData: '',
  dataList: [
    {
      firstName: 'Omolara',
      lastName: 'Johnson',
      code: 'P0000123',
    },
    {
      firstName: 'Jacob',
      lastName: 'Mills',
      code: 'P0000124',
    },
    {
      firstName: 'Max',
      lastName: 'Strus',
      code: 'P0000125',
    },
    {
      firstName: 'john',
      lastName: 'Doe',
      code: 'P0000126',
    },
    {
      firstName: 'Sam',
      lastName: 'Smith',
      code: 'P0000127',
    },
  ],
};

export const fleetTrackerTableDataSample = {
  perPage: '',
  pages: '',
  totalData: '',
  dataList: [
    {
      WaybillNumbers: '',
      deliveryOption: '',
      price: '',
    },
  ],
};

export const fleetDispatchTableDataSample = {
  perPage: '',
  pages: '',
  totalData: '',
  dataList: [
    {
      date: '',
      truckRegistration: '',
      manifest: '',
      amount: '',
      departure: '',
      destination: '',
      category: '',
      captain: '',
    },
  ],
};

export const fleetCaptainTableDataSample = {
  perPage: '',
  pages: '',
  totalData: '',
  dataList: [
    {
      captainName: '',
      captainCode: '',
      email: '',
      assignedVehicle: '',
      description: '',
      date: '',
    },
  ],
};

export const fleetvehicleTableDataSample = {
  perPage: '',
  pages: '',
  totalData: '',
  dataList: [
    {
      vehicleName: '',
      vehicleNumber: '',
      vehicleOwnerNumber: '',
      assignedCaptain: '',
      vehicleAge: '',
      date: '',
      revenueStatus: '',
    },
  ],
};
