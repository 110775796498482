import { inputCancelIcon } from 'assets'
import { SpinLoader } from 'components/util-components/SpinLoader'
import PhoneInput, { Country } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { useState } from 'react'

type IPhoneInputProps = {
    label?: string
    value?: string
    setValue?: (e: any) => void
    required?: boolean
    className?: string
    disabled?: boolean
    name?: string
    errorMessage?: string
    phoneInputClass?: string
    isRequiredIconPosition?: "left" | "right"
    loading?: boolean
    defaultCountry?: Country
    allowCancel?: boolean
    keyPress?: boolean
    clearInput?:boolean
}

export const PhoneNumberInput = ({
    label,
    className,
    value,
    name,
    setValue,
    loading,
    phoneInputClass,
    required,
    isRequiredIconPosition,
    defaultCountry,
    allowCancel = true,
    keyPress = false
}: IPhoneInputProps) => {

    const [inputValue, setInputValue] = useState(value || "");

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' && setValue) {
            setValue(inputValue);
        }
    };

    const handleChange = (value: string) => {
        if (keyPress === true) {
            setInputValue(value || "");
        }
        else if (setValue) {
            setValue(value || "");
        }
        else {
            return
        }
    };



    return (
        <div className={`flex flex-col ${className}`}>
            <div className="w-full flex ">
                {required && isRequiredIconPosition === "left" && <span className="text-col_ff0000 mr-[3px]"> * </span>}
                {label && (
                    <label className='font-fontInter text-[0.75rem] leading-normal font-normal text-col_525252 capitalize mb-1'>
                        {label}
                    </label>
                )}
                {required && isRequiredIconPosition !== 'left' && (
                    <span className="text-col_ff0000 ml-[3px]"> * </span>
                )}
            </div>
            <div className="w-full flex items-center gap-2 bg-col_f8f8f8 rounded-[0.5rem] py-1">
                <PhoneInput
                    placeholder="Enter phone number"
                    value={keyPress === true ? inputValue : value || value}
                    onChange={handleChange}
                    name={name}
                    defaultCountry={defaultCountry ? defaultCountry : 'NG'}
                    international
                    className={`phone-number-input w-full ${phoneInputClass}`}
                    onKeyPress={handleKeyPress}
                />
                {
                    loading ? <SpinLoader /> : value && !loading && allowCancel ?
                        <img onClick={() => (setValue && setValue(""), setInputValue && setInputValue(""))} src={inputCancelIcon} alt="cancel icon" className='z-9999 cursor-pointer w-[1.2rem] ml-auto' />
                        : null
                }
            </div>
        </div>
    )
}
