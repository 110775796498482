

import { lazy } from 'react';

export const Pages = {
  Dashboard: lazy(() =>
    import('pages/dashboard/Dashboard').then((module) => ({
      default: module.Dashboard
    }))),

  CaptureShipments: lazy(() =>
    import('pages/shipments/capture-shipments/CaptureShipments').then((module) => ({
      default: module.CaptureShipments
    }))),
    SalesCustomerManagement: lazy(() =>
      import('pages/sales/CustomerManagement').then((module) => ({
        default: module.SalesCustomerManagement
      }))),

  Notifications: lazy(() =>
    import('pages/Notifications').then((module) => ({
      default: module.Notifications
    }))),
  DailySales: lazy(() =>
    import('pages/shipments/daily-sales/DailySales').then((module) => ({
      default: module.DailySales
    }))),

  shipmentInvoice: lazy(() =>
    import('pages/shipments/daily-sales/shipment-invoice/ShipmentInvoiceDetail').then((module) => ({
      default: module.ShipmentInvoiceDetail
    }))
  ),

  PaymentOptions: lazy(() =>
    import('pages/shipments/capture-shipments/PaymentOptions').then((module) => ({
      default: module.PaymentOptions
    }))
  ),
  CreateReturnShipment: lazy(() =>
    import('pages/shipments/return-shipment/CreateReturnShipment').then((module) => ({
      default: module.CreateReturnShipment
    }))
  ),

  InvoiceCaptureShipment: lazy(() =>
    import('pages/shipments/capture-shipments/InvoiceCaptureShipment').then((module) => ({
      default: module.InvoiceCaptureShipment
    }))),



    TransferManagement: lazy(() =>
      import('pages/shipments/transfer-management/TransferManagement').then((module)=>({
        default:module.TransferManagement
      }))
    ),


  CodProcessingOrder: lazy(() =>
    import('pages/shipments/cod-processing-order/CodProcessingOrder').then(
      (module) => ({
        default: module.CodProcessingOrder,
      }),
    ),
  ),


  ReturnShipment : lazy(() =>
    import('pages/shipments/return-shipment/ReturnShipment').then((module) => ({
      default: module.ReturnShipment,
    }))
  ),

  DemurrageProcessingOrder: lazy(() =>
    import(
      'pages/shipments/demurrage-processing-order/DemurrageProcessingOrder'
    ).then((module) => ({
      default: module.DemurrageProcessingOrder,
    })),
  ),


  BankProcessingOrder: lazy(() =>
    import('pages/shipments/bank-processing-order/BankProcessingOrder').then((module) => ({
      default: module.BankProcessingOrder
    }))),

  GroupShipmentView: lazy(() =>
    import('pages/processing-and-packaging/groups-and-packages/GroupShipmentView').then((module) => ({
      default: module.GroupShipmentView
    }))),


  AddGroupShipmentView: lazy(() =>
    import(
      'pages/processing-and-packaging/groups-and-packages/AddGroupShipmentView'
    ).then((module) => ({
      default: module.AddGroupShipmentView,
    }))
  ),
  Manifest: lazy(() =>
    import('pages/processing-and-packaging/manifest/Manifest').then(
      (module) => ({
        default: module.Manifest,
      }),
    ),
  ),

  GenerateManifest: lazy(() =>
    import('pages/processing-and-packaging/manifest/GenerateManifest').then(
      (module) => ({
        default: module.GenerateManifest,
      }),
    ),
  ),

   ManifestDetail : lazy(() =>
    import('pages/processing-and-packaging/manifest/ManifestDetail').then(
      (module) => ({
        default: module.ManifestDetail,
      }),
    ),
  ),

  PandPWaybillReport :lazy(() =>
    import(
      'pages/processing-and-packaging/manifest/ManifestWaybillReport'
    ).then((module) => ({
      default: module.ManifestWaybillReport,
    })),
  ),


   ManifestinvoiceDetail: lazy(() =>
    import('pages/processing-and-packaging/manifest/manifest-invoice/ManifestinvoiceDetail').then(
      (module) => ({
        default: module.ManifestinvoiceDetail
      }),
    ),
  ),
  
  InboundShipments : lazy(() =>
    import('pages/shipment-tracking/InboundShipments').then((module) => ({
      default: module.InboundShipments,
    }))
  ),

   ShipmentStatus : lazy(() =>
    import('pages/shipment-tracking/ShipmentStatus').then((module) => ({
      default: module.ShipmentStatus,
    })),
  ),
  MovementMenifest : lazy(() =>
    import('pages/processing-and-packaging/movement-manifest/MovementManifest').then((module) => ({
      default: module.MovementManifest,
    })),
  ),
  GenerateMovementManifest : lazy(() =>
    import('pages/processing-and-packaging/movement-manifest/GenerateMovementManifest').then((module) => ({
      default: module.GenerateMovementManifest,
    })),
  ),

  VerificationOtp: lazy(() =>
    import(
      'pages/shipments/capture-shipments/VerificationOtp'
    ).then((module) => ({
      default: module.VerificationOtp,
    }))
  ),


InboundShipment : lazy(() =>
    import('pages/international/inbound-shipments/InboundShipment').then(
      (module) => ({
        default: module.InboundShipment,
      })
    )
  ),


   RegionHomeDeliveryShipment :lazy(() =>
    import('pages/region/home-delivery-shipment/HomeDeliveryShipment').then(
      (module) => ({
        default: module.HomeDeliveryShipment,
      }),
    )
  ),

   DeliveryLocations : lazy(() =>
    import('pages/shipments/delivery-locations/DeliveryLocations').then(
      (module) => ({
        default: module.DeliveryLocations,
      }),
    ),
  ),


   PayForShipmentByWallet : lazy(() =>
    import(
      'pages/admin-options/pay-for-shipment-by-wallet/PayForShipmentByWallet'
    ).then((module) => ({
      default: module.PayForShipmentByWallet,
    })),
  ),


  EcommerceQuickQuotes : lazy(() =>
    import(
      'pages/processing-and-packaging/quick-quotes/EcommerceQuickQuotes'
    ).then((module) => ({
      default: module.EcommerceQuickQuotes,
    })),
  ),

  PayForShipmentByWalletEcommerce : lazy(() =>
    import(
      'pages/shipments/pay-for-shipment/PayForShipmentByWalletEcommerce'
    ).then((module) => ({
      default: module.PayForShipmentByWalletEcommerce,
    })),
  ),  

  MoveToNewManifest : lazy(() =>
    import(
      'pages/processing-and-packaging/move-manifest/MoveToNewManifest'
    ).then((module) => ({
      default: module.MoveToNewManifest,
    })),
  ),

  MovementManifest : lazy(() =>
    import(
      'pages/processing-and-packaging/movement-manifest/MovementManifest'
    ).then((module) => ({
      default: module.MovementManifest,
    })),
  ),

  //  GenerateMovementManifest : lazy(() =>
  //   import(
  //     'pages/processing-and-packaging/movement-manifest/GenerateMovementManifest'
  //   ).then((module) => ({
  //     default: module.GenerateMovementManifest,
  //   })),
  // ),

  InternationalShipment : lazy(() =>
    import(
      'pages/international/international-shipment/InternationalShipment'
    ).then((module) => ({
      default: module.InternationalShipment,
    })),
  ),

  CaptureIntlShipments : lazy(() =>
    import('pages/shipments/capture-shipments/CaptureIntlShipments').then(
      (module) => ({
        default: module.CaptureIntlShipments,
      }),
    ),
  ),

}

 






