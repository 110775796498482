import { useQuery } from '@tanstack/react-query';
import { getShipmentDetailsByIdApi } from 'apiRequest/daily-sales/dailySalesRequest';
import { loader } from 'assets';
// import { Header } from 'components/header/Header';
// import { Table } from 'components/table/Table';
import { IoIosArrowForward } from "react-icons/io";
import { format } from 'date-fns';
import { pickupOptions } from 'utils/shipmentConstants';

const DisplayCustomerInfo = ({ title, value }: {
  title: string
  value: string
}) => {
  return (
    <div className="flex flex-col py-4 align-middle border-b border-col_e9e9e9 bg-opacity-[40%] last:border-0">
      <div className='flex items-center align-middle justify-between  w-full h-full'>
        <div className="tb:w-[50%] text-sm font-semibold text-col_525252">
          {title}
        </div>
        <p className="tb:w-[50%] text-sm text-right font-normal text-col_525252">
          {value}
        </p>
      </div>
    </div>
  )
}


interface props {
  isOpen?: boolean;
  onClose?: any;
  shipmentId?: any;
  Waybill?: any
}
const DialogBox = ({
  isOpen,
  onClose,
  shipmentId,
  Waybill,
}: props ) => {
  // Query to fetch shipment details data from the API

 
  const { data, isLoading } = useQuery({
    queryKey: ['shipmentDetailsById', shipmentId],
    queryFn: async () => {
      // Build the query string dynamically
      const queryParams = [];
      if (shipmentId) queryParams.push(`ShipmentId=${shipmentId}`);
      if (Waybill) queryParams.push(`Waybill=${Waybill}`);
      
      // Combine the query parameters
      const queryString = queryParams.join('&');
  
      // Call the API with the constructed query string
      return await getShipmentDetailsByIdApi(queryString);
    },
  });

  if (!isOpen) return null;

  // Handle loading and error states
  if (isLoading) {
    return (
      <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-900 bg-opacity-50">
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <img src={loader} />
        </div>
      </div>
    );
  }

  // if (isError) {
  //   return (
  //     <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-900 bg-opacity-50">
  //       <div className="bg-white p-6 rounded-lg shadow-lg">
  //         <p>Error loading shipment details.</p>
  //       </div>
  //     </div>
  //   );
  // }

  // Destructure data safely using optional chaining
  const { ShipmentDetails, SenderDetails, ReceiverDetails } =
    data?.data || {};
  return (
    <>
      {data?.data ? (

        <div>

          {/* <Header
          announcement={true}
          category="Shipments"
          subCategory="Shipment Details"
        /> */}

          <div className="">
            <header className=" px-4 shadow-xl pt-4 pb-2 mb-4  bg-col_f6f6f6 bg-opacity-55">
              <div className="flex justify-between">
                <div className="flex gap-2 items-center text-[14px] font-normal text-gray-400">
                  <h2>Shipments</h2>
                  <span>
                    <IoIosArrowForward />
                  </span>
                  <h2>Shipments Details</h2>
                </div>
                <div>
                  <button
                    onClick={onClose}
                    className="  text-gray-500 hover:text-gray-700"
                  >
                    &times;
                  </button>
                </div>
              </div>
              <h2 className="text-[20px] font-light ">Shipment Details</h2>
            </header>
            <main className="">
              <div className="grid md:grid-cols-2 gap-4 my-5">

                <div className="w-full overflow-hidden shadow-md">
                  <div className="bg-col_e84e40 rounded-t-xl py-[0.75rem] px-4 flex items-center text-col_ffffff text-[0.875rem] font-[600] leading-normal capitalize">
                    Shipment Details
                  </div>

                  <div className="pb-6 rounded-t-xl ">
                    <div className="bg-white py-4 px-6">
                      <DisplayCustomerInfo
                        title="Waybill"
                        value={ShipmentDetails?.Waybill}
                      />
                      <DisplayCustomerInfo
                        title="Depature Service Centre"
                        value={ShipmentDetails?.DepartureServiceCentre}
                      />
                      <DisplayCustomerInfo
                        title="Destination Service Centre"
                        value={ ShipmentDetails?.DestinationServiceCentre}
                      />
                      <DisplayCustomerInfo
                        title="Pickup Options"
                        value={pickupOptions[ShipmentDetails?.PickupOptions]}
                      />
                      <DisplayCustomerInfo
                        title="Date Created"
                        value={ShipmentDetails?.DateCreated ? format(new Date(ShipmentDetails.DateCreated), 'dd MMMM yyyy hh:mm a') : 'Invalid date'}
                      />
                      <DisplayCustomerInfo
                        title="Value of Shipment"
                        value={ShipmentDetails?.Value}
                      />
                      <DisplayCustomerInfo
                        title="VAT"
                        value={ShipmentDetails?.Vat?.toFixed(2)}
                      />
                      <DisplayCustomerInfo
                        title="Shipment Package Price"
                        value={ShipmentDetails?.ShipmentPackagePrice}

                      />
                      <DisplayCustomerInfo
                        title="Discount Value"
                        value={ShipmentDetails?.DiscountValue}

                      />
                      <DisplayCustomerInfo
                        title="Grand Total"
                        value={ShipmentDetails?.GrandTotal?.toFixed(2)}

                      />
                      <DisplayCustomerInfo
                        title="Receiver Delivery Code"
                        value={ShipmentDetails?.ReceiverCode}
                      />
                      <DisplayCustomerInfo
                        title="Sender Delivery Code"
                        value={ShipmentDetails?.SenderCode}
                      />
                    </div>

                    {/* <div className="px-8 py-3 bg-col_f6f6f6 bg-opacity-55">
                    Shipment(s) Size/Dimension
                  </div>

                  <div className="bg-white px-8 py-6 space-y-8 rounded-b-xl">
                    <Table
                      tableColumns={tableHeader}
                      tableRows={tableRow}
                      className='w-full mb-4'
                    />

                    <div>
                      <p className='text-txt_087_600 text-col_525252 mb-4'>Shipment(s) Description</p>
                      <p className='text-txt_087_400 text-col_525252'>One 12kg bag of rice, Used iPhone 14 Pro Max and a charger</p>
                    </div>
                  </div> */}
                  </div>
                </div>

                <div className="space-y-5">
                  <div className='shadow-md'>
                    <div className="bg-col_e84e40 rounded-t-xl py-[0.75rem] px-4 flex items-center text-col_ffffff text-[0.875rem] font-[600] leading-normal capitalize">
                      Senders Details
                    </div>
                    <div className="bg-white px-6 py-4 rounded-b-xl">
                      <DisplayCustomerInfo
                        title="Sender’s Name"
                        value={`${SenderDetails?.FirstName ?? ''} ${SenderDetails?.LastName ?? ''}`}
                      />
                      <DisplayCustomerInfo
                        title="Sender’s Mobile Number"
                        value={SenderDetails?.PhoneNumber}
                      />
                      <DisplayCustomerInfo
                        title="Sender’s Address"
                        value={SenderDetails?.Address}
                      />
                      <DisplayCustomerInfo
                        title="Sender’s Email"
                        value={SenderDetails?.Email}
                      />
                      <DisplayCustomerInfo
                        title="Customer Category"
                        value={SenderDetails?.CustomerCode}
                      />
                      <DisplayCustomerInfo
                        title="Gender"
                        value={SenderDetails?.Gender}
                      />
                    </div>
                  </div>

                  <div className='shadow-md'>
                    <div className="bg-col_e84e40 rounded-t-xl py-[0.75rem] px-4 flex items-center text-col_ffffff text-[0.875rem] font-[600] leading-normal capitalize">
                      Receiver Details
                    </div>
                    <div className="bg-white px-6 py-4 rounded-b-xl">
                      <DisplayCustomerInfo
                        title="Receiver’s Name"
                        value={ReceiverDetails?.ReceiverName}
                      />
                      <DisplayCustomerInfo
                        title="Receiver’s Mobile Number"
                        value={ReceiverDetails?.ReceiverPhoneNumber}
                      />
                      <DisplayCustomerInfo
                        title="Receiver’s Address"
                        value={ReceiverDetails?.ReceiverAddress}
                      />
                      <DisplayCustomerInfo
                        title="Receiver’s Email"
                        value={ReceiverDetails?.ReceiverEmail}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      ) : (
        <>
          <div className='flex justify-end'>

            <button
              onClick={onClose}
              className=" text-gray-500 hover:text-gray-700"
            >
              &times;
            </button>
          </div>
          <p className="my-6">No Data found for this shipment!</p>

        </>
      )}
    </>
  );
};

export default DialogBox;

// const tableHeader = [
//   {key: "no", label: "#", component: null},
//   {key: "description", label: "Description", component: null},
//   {key: "nature", label: "Nature", component: null},
//   {key: "weight", label: "Weight/Volumn(H*L*W)cm", component: null},
// ]

// const tableRow = [
//   {no: "i", description: "12kg", nature: "normal", weight: "2kg"},
//   {no: "ii", description: "24kg", nature: "special", weight: "24kg"},
//   {no: "iii", description: "9kg", nature: "normal", weight: "2kg"},
// ]