import { Dispatch, SetStateAction, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Button } from 'components/button/Button';
import 'react-datepicker/dist/react-datepicker.css';
import { FaCalendarAlt } from 'react-icons/fa'; // Calendar icon (Font Awesome)

// type ValuePiece = Date | null;
// type Value = ValuePiece | [ValuePiece, ValuePiece];

const MyContainer = ({ children }: any) => {
  return (
    <div className="w-full tb:w-[21rem] z-50 rounded-2xl p-[1rem] bg-[#fff] my-date-container">
      <div className="w-full h-full">{children}</div>
    </div>
  );
};

const DateRangeCalendar = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}: {startDate:Date|null|undefined,endDate:Date|any, setStartDate:Dispatch<SetStateAction <Date|any>>, setEndDate:Dispatch<SetStateAction <Date|any>>}) => {
  // const [previousSelectedStartDate, setPreviousSelectedStartDate] = useState();

  const [isDatePickerOpen, setIsDatePickerOpen] = useState<boolean>(false);
  const [value, setValue] = useState({
    start: null,
    end: null,
  });

  const handleDateChange = (dates: any) => {
    const [start, end] = dates;
    setStartDate(start);
    setValue({
      start: start,
      end: end,
    });
  };

  const handleApply = () => {
    setStartDate(value.start);
    setEndDate(value.end);
    setIsDatePickerOpen(false);
  };

  const handleCancel = () => {
    setIsDatePickerOpen(false);
    setStartDate(value.start);
    setEndDate(value.end?? new Date());
    // setEndDate(new Date());
  };

  const handleCalendarOpen = () => {
    setStartDate(null);
    setEndDate(null);
  };

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        setIsDatePickerOpen(true);
      }}
      className="flex items-center bg-[#fff] cursor-pointer rounded-[5px] relaive z-50 my-date-container"
    >
      <FaCalendarAlt
        onClick={() => (handleCalendarOpen())}
        className="text-[#807E7C] mr-2"
      />{' '}
      {/* Calendar Icon */}
      <DatePicker
        onInputClick={handleCalendarOpen}
        placeholderText="Choose Date Range"
        portalId="date-popper"
        // selected={startDate}
        // showFullMonthYearPicker
        startDate={startDate}
        endDate={endDate}
        selectsRange
        onChange={handleDateChange}
        className="ring-0 focus:border-0 border-0 bg-col_fafafa w-52 agility-calendar cursor-pointer"
        calendarClassName="gap-4 ring-0 cal-classname"
        calendarContainer={MyContainer}
        showYearDropdown
        required
        popperClassName="popper-cn"
        forceShowMonthNavigation
        scrollableMonthYearDropdown
        shouldCloseOnSelect={false}
        showPopperArrow
        dateFormat={'yyyy-MM-dd'}
        // dateFormatCalendar=''
        // isClearable
        open={isDatePickerOpen}
        onClickOutside={handleCancel}
        // onCalendarOpen={handleCalendarOpen}
        // clearButtonClassName="bg-red-400"
      >
        <div className="flex items-center w-[200px] gap-x-4 relative">
          <Button
            text="Cancel"
            className="w-[8.5rem] h-[3rem] rounded-[0.8rem] py-3 px-6 flex items-center justify-center text-txt_087_normal text-[#000] bg-[#fff] border border-[#807E7C]"
            onClick={(e) => {
              e.stopPropagation();
              handleCancel();
            }}
          />
          <Button
            disabled={startDate === null || value.end===null}
            text="Apply"
            className="w-[8.5rem] h-[3rem] rounded-[0.8rem] py-3 px-6 flex items-center justify-center text-txt_087_normal text-col_fff bg-col_e84e40 z-50
            "
            onClick={(e) => {
              e.stopPropagation();
              handleApply();
            }}
          />
        </div>
      </DatePicker>
    </div>
  );
};

export default DateRangeCalendar;