import { useState, useEffect } from 'react';
import { close_modal_icon } from 'assets';
import styles from 'styles/modal.module.scss';
import { cn } from 'utils/cn';

interface Props {
  modalChild: React.ReactNode;
  modalOpen: boolean;
  modalClose?: () => void;
  closeIcon?: boolean;
  title?: string;
  modalClass?: string;
  closeAction?: () => void;
  modalPatterBg?: boolean;
  animateModal?: boolean;
  modalTitleClassName?: string
}

export const Modal = ({
  modalChild,
  modalOpen,
  modalClose,
  closeAction,
  title,
  closeIcon,
  modalClass,
  modalPatterBg,
  modalTitleClassName,
}: Props) => {
  const [modal, setModal] = useState(false);
  useEffect(() => {
    setModal(modalOpen);
  }, [modalOpen]);
if(!modalChild)  return null
  return (
    <div
      className={modal ? styles?.modalCont : styles?.hidden}
      onClick={() => modalClose && modalClose()}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className={styles?.contentCont + ` slide-up-content-reveal `}
      >
        <div
          className={`w-full h-full py-4 px-2 rounded-[10px] ${
            modalPatterBg ? styles?.modal_inner_cont : 'bg-transparent'
          }`}
        >
          {(closeIcon || title) && (
            <div className="title w-full flex justify-between items-center mb-1">
              <span className={cn("text-text-darkBlue text-[18px] leading-[27px] font-[500]", modalTitleClassName)}>
                {title}  
              </span>
              <img
                src={close_modal_icon}
                alt=""
                className="cursor-pointer"
                onClick={() => {
                  if (closeAction) {
                    closeAction && closeAction();
                  } else {
                    modalClose && modalClose();
                  }
                }}
              />
            </div>
          )}
          <div className={`mx-4 ${modalClass}`}>{modalOpen && modalChild}</div>
        </div>
      </div>
    </div>
  );
};

