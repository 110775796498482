// import { red_arrow_down } from 'assets';
import { SpinLoader } from 'components/util-components/SpinLoader';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import { useState, useRef, useEffect, ReactNode } from 'react';
import { IoIosArrowDown } from 'react-icons/io';


export interface IDropdownProps {
  dropdownContent?: ReactNode;
  options?: { key: string; value: any }[];
  selected?: any;
  placeholder?: string;
  disabled?: boolean;
  loading?: boolean;
  searchable?: boolean;
  prefixLabel?: string;
  suffixLabel?: string;
  label?: string;
  className?: string;
  dropdownClassName?: string;
  setValue?: (e: any) => void;
  isClearable?: boolean;
  errorMessage?: string;
  required?: boolean;
  name?: string;
  showFloatingLabel?: boolean;
  clearInput?: boolean
  value?: string | number | boolean;
}

export const Dropdown = ({
  label,
  options,
  // dropdownContent,
  prefixLabel,
  suffixLabel,
  required,
  placeholder,
  searchable,
  setValue,
  loading,
  className,
  showFloatingLabel = false,
  selected,
  dropdownClassName,
  clearInput,
  // name
}: IDropdownProps) => {
  const [isDropdown, setIsDropdown] = useState(false);
  const [output, setOutput] = useState('');
  const [searchKey, setSearchKey] = useState('');

  const dropRef = useRef(null);

  useOnClickOutside(dropRef, () => {
    setIsDropdown(false);
  });

  const getDisplayValue = () => {
    if (searchKey && isDropdown) return searchKey;
    if (output && !isDropdown) return output;
    if (!output && !isDropdown && selected) return selected

    return '';
  };



  useEffect(() => {
    if (clearInput) {
      setOutput("")
      setSearchKey("")
    }
  }, [clearInput])

  const filterData = () => {
    return options?.filter((item: any) => {
      if (searchable) {
        return item['key']?.toLowerCase().includes(searchKey?.toLowerCase());
      } else {
        return options;
      }
    });
  };


  return (
    <div className={`relative ${className}`}>
      {label && (
        <div className="w-full flex items-center mb-1">
          {prefixLabel && <img src={prefixLabel} alt="" className="ml-2" />}
          <label
            className={`text-[0.75rem] capitalize font-[400] text-col_525252 `}
          >
            {label}
          </label>
          {suffixLabel && <img src={suffixLabel} alt="" className="ml-2" />}
          {required && <span className="text-col_ff0000 ml-[3px]"> * </span>}
        </div>
      )}
      <div className='w-full h-full'>
        <div
          className={`relative w-full py-[0.35rem] px-3 flex align-middle items-center justify-between cursor-pointer bg-col_fafafa ${isDropdown ? 'border border-col_e84e40' : 'border-0'} rounded-[0.4rem] ${dropdownClassName}`}
          onClick={() => setIsDropdown(!isDropdown)}
        >
          <div className="w-full flex flex-col py-0">
            {showFloatingLabel && !isDropdown && getDisplayValue() && (
              <div
                className={`w-full font-normal text-[0.625rem] text-[#807E7C] pb-1`}
              >
                {placeholder}
              </div>
            )}
            {showFloatingLabel && isDropdown && selected !== getDisplayValue() && (
              <div
                className={`w-full font-normal text-[0.625rem] text-[#219653] pb-1`}
              >
                {placeholder}
              </div>
            )}
            <input
              type="text"
              value={ getDisplayValue() }
              placeholder={placeholder}
              onChange={(e) => {
                setSearchKey(e.target.value);
              }}
              className={`w-full text-[0.75rem] py-1 outline-none bg-transparent text-col_807e7c capitalize placeholder-[#BFBEBD] ${!isDropdown ? 'cursor-pointer' : 'cursor-default'}`}
            />
          </div>
          <div
            // src={red_arrow_down}
            // alt=""
            className={`ml-1 w-[0.75rem] ${isDropdown ? 'rotate-180' : 'rotate-0'}`} >
              <IoIosArrowDown />
            </div>
         
        </div>
        {isDropdown ? (
          <div
            ref={dropRef}
            className="absolute top-[120%] bg-col_fafafa w-full max-h-[10rem] lg:max-h-[15rem] overflow-y-auto py-1 px-2 rounded-[0.4rem] border border-col_807e7c border-opacity-[40%] z-[100]"
          >
            {loading ? (
              <div className="w-full flex items-center justify-center py-2 px-2 rounded-[0.4rem] text-txt_075_400 text-col_807e7c capitalize">
                <SpinLoader />
              </div>
            )
              : filterData()?.length ? (
                filterData()?.map((item: any, i: number) => (
                  <p
                    key={i}
                    onClick={() => {
                      setValue && setValue(item.value);
                      setOutput(item.key);
                      setIsDropdown(false);
                      setSearchKey('');

                    }}
                    className="w-full py-2 px-2 rounded-[0.4rem] hover:bg-col_e84e40 text-txt_075_400 text-col_807e7c hover:text-col_fff cursor-pointer capitalize"
                  >
                    {item?.key}
                  </p>
                ))
              ) : (
                <p className="w-full flex items-center justify-center py-2 px-2 rounded-[0.4rem] text-txt_075_400 text-col_807e7c capitalize">
                  No items
                </p>
              )}
          </div>
        )
          // : isDropdown && dropdownContent !== undefined ? (
          //   <div
          //     ref={dropRef}
          //     className="absolute top-[120%] bg-col_fafafa w-full py-1 px-2 rounded-[0.4rem] border border-col_807e7c border-opacity-[40%] z-[500]"
          //   >
          //     <p>dropdown content</p>
          //     {/* {dropdownContent} */}
          //   </div>
          // ) 
          : null}
      </div>
    </div>
  );
};
