import { Dropdown } from "components/dropdown/Dropdown"
import { TextInput } from "components/inputs/TextInput"
import { PhoneNumberInput } from "components/phoneNumberInput/PhoneNumberInput"
import { Radio } from "components/radio/Radio"


type Props = {
    contentArr?: any
    className?: string
}

export const FormDetailCard = ({contentArr, className}: Props) => {
  return (
    <div className={`w-full rounded-[1rem] shadow-header_shadow bg-col_fff py-3 px-4 mx-auto ${className}`}>
        {contentArr?.map((item: any, i: number) => (
            <div key={i}>
                {(item.type === "text" || item.type === "textarea" || item.type === "search") && (
                    <TextInput
                        label={item.label}
                        type={item.type}
                        value={item.value}
                        setValue={item.setValue}
                        required={item.required}
                        rows={item.rows}
                        placeholder={item.placeholder}
                        className={`w-full ${item.className}`}
                    />
                )}
                {(item.type === "phoneNumber" || item.type === "phone") && (
                    <PhoneNumberInput
                        label="phone number"
                        value={item.phone}
                        setValue={item.setPhone}
                        required={item.required}
                        className={`w-full ${item.className}`}
                    />
                )}
                {item.type === "dropdown" && (
                    <Dropdown
                        placeholder={item.placeholder}
                        label={item.label}
                        options={item.optionsArr}
                        selected={item.dropdownSelected}
                        setValue={item.setDropdownSelected}
                        searchable={item.isSearchable}
                        required={item.required}
                        className={`w-full ${item.className}`}
                    />
                )}
                {item.type === "radio" && (
                    <div className={item.className}>
                        <p className='text-col_525252 text-[0.875rem] font-normal capitalize mb-2'>
                            {item.label}
                        </p>
                        <div className="w-full flex md:items-center gap-4">
                            {item.options.map((ele: string, i: number) => (
                                <Radio
                                    key={i}
                                    label={ele}
                                    name={item.label}
                                    defaultValue={item.options[0]}
                                    value={item.value}
                                    onClick={() => item.setValue(ele)}
                                    labelClass='capitalize text-[0.875rem]'
                                    className='radio'
                                />
                            ))}
                        </div>
                    </div>
                )}
            </div>
        ))}
    </div>
  )
}