import { useEffect, useState } from 'react'
import { IconType } from 'react-icons';

import { dark_arrow_right, headerClockIcon } from 'assets'
import { Button } from 'components/button/Button'
import { Navbar } from 'components/nav/Navbar'
import { useLocation } from 'react-router-dom';
import { SHIPMENTS_ROUTES } from 'utils/routesEnums';

export interface IButtonDetails {
    text: string
    type: 'grey-tab' | 'red-tab' | 'green-tab' | 'current-tab' | 'white-btn' | 'red-btn' | 'red-border'
    tab?: string | number
    prefixIcon?: string
    suffixIcon?: string
    route?: string
    isvisible?: boolean
    onClick?: () => void
    disabled?: boolean
    authSuffixBtnIcon?: boolean
}

type IHeaderProps = {
    category?: string
    subCategory?: string
    thirdCategory?: string
    type?: string
    title?: string
    mainRoute?: string
    headerRedTopBtn?: {
        text: string,
        prefixIcon?: string,
        suffixIcon?: string,
        onClick?: () => void,
        className?: string
        PreIcon?: IconType,
        PreIconClassName?: string
    }
    greenTimerBtn?: boolean
    className?: string
    headerTabs?: IButtonDetails[]
    headerNaviagtionBtn?: IButtonDetails[]
    announcement?: boolean
}

export const Header = ({ title, category, subCategory, thirdCategory, type, headerRedTopBtn, headerTabs, headerNaviagtionBtn, className, greenTimerBtn = true }: IHeaderProps) => {

    const loaction = useLocation()

    // console.log(loaction)

    useEffect(() => {
        if (loaction.pathname !== SHIPMENTS_ROUTES.payment) {
            localStorage.removeItem("waybillNumbers");
            localStorage.removeItem("grandTotal")
        }
    }, [loaction.pathname])


    const [time] = useState({
        minutes: new Date().getMinutes(),
        hours: new Date().getHours(),
        seconds: new Date().getSeconds(),
        day: new Date().getDate(),
        month: new Date().toLocaleString('default', { month: 'short' }),
        year: new Date().getFullYear()
    })

    const convertToTwoDigit = (number: number) => {
        return number.toLocaleString('en-US', {
            minimumIntegerDigits: 2
        })
    }

    return (
        <header
            className={`header-cont ${headerTabs?.length || headerNaviagtionBtn?.length ? "tb:h-[12rem]" : "h-[8.5rem]"} 
        fixed top-0 right-0 left-0 tb:left-[14rem] z-[200]  bg-col_fff  ${className} box-border`}
        >
            <Navbar className='nav-cont w-full h-[4rem] box-border' />

            <div className='px-4 md:px-5'>
                <div className={`top w-full h-[4rem] pt-[0.5rem] pb-[0.2rem] flex flex-col md:flex-row tb:items-center justify-between  box-border`}>
                    <div className="left h-auto gap-1 md:gap-2">
                        <div className='flex items-center gap-2'>
                            {category && (
                                <span className='text-col_807e7c normal-text capitalize'
                                >
                                    {category}
                                </span>
                            )}
                            {subCategory && (
                                <div className='flex'>
                                    <img src={dark_arrow_right} alt="" className="px-2" />
                                    <span className={`text-col_807e7c font-fontInter text-[0.75rem] md:text-[0.875rem] ${thirdCategory ? "font-normal" : "font-bold"} capitalize ml-2`}>
                                        {subCategory}
                                    </span>
                                </div>
                            )}
                            {thirdCategory && (
                                <div className='flex'>
                                    <img src={dark_arrow_right} alt="" className="px-2" />
                                    <span className='text-col_807e7c font-fontInter text-[0.75rem] md:text-[0.875rem] font-bold capitalize ml-2'>
                                        {thirdCategory}
                                    </span>
                                </div>
                            )}
                        </div>
                        <div className="flex pt-1">
                            <span className='topic-text text-col_525252'>{title ? title : thirdCategory ? thirdCategory : subCategory}</span>
                            <span className='topic-text text-col_525252 ml-2'>{type}</span>
                        </div>
                    </div>

                    <div className="right hidden md:flex items-center gap-2 ">
                        {headerRedTopBtn && (
                            <Button
                                text={headerRedTopBtn.text}
                                prefixIcon={headerRedTopBtn?.prefixIcon}
                                suffixIcon={headerRedTopBtn?.suffixIcon}
                                onClick={() => headerRedTopBtn.onClick && headerRedTopBtn.onClick()}
                                className={`btn-red-active py-2 px-2 md:px-6 rounded-[0.9rem] ${headerRedTopBtn.className}`}
                                PreIcon={headerRedTopBtn?.PreIcon}
                                PreIconClassName={headerRedTopBtn?.PreIconClassName}
                            />
                        )}

                        {greenTimerBtn && (
                            <Button
                                text={`${time.day}, ${time.month} ${time.year} 
                                ${convertToTwoDigit(time.hours)}:${convertToTwoDigit(time.minutes)}:${convertToTwoDigit(time.seconds)}`
                                }
                                prefixIcon={headerClockIcon}
                                className='btn-green-active rounded-[0.9rem] py-2 px-2 md:px-6'
                            />
                        )}

                    </div>
                </div>
                <div
                    className={`${headerTabs?.length || headerNaviagtionBtn?.length ? "hidden tb:h-[3.5rem] tb:w-full tb:flex items-center justify-between mt-0 md:mt-0 " : "hidden"} `}
                >
                    {headerTabs &&
                        <div className="tabs flex items-center gap-2">
                            {headerTabs.map((button, i) => (
                                <Button
                                    key={i}
                                    onClick={button.onClick}
                                    text={button.text}
                                    prefixIcon={button.prefixIcon}
                                    suffixIcon={button.suffixIcon}
                                    tab={button.tab}
                                    tabClass={`text-col_ffffff
                                    ${button.type === "grey-tab" && "bg-col_807e7c"}
                                    ${button.type === "red-tab" && "bg-col_ff0000 "}
                                    ${button.type === "green-tab" && "bg-col_66bb6a"}
                                    ${button.type === "current-tab" && "bg-col_807e7c"}
                                `}
                                    className={`tab text-ellipsis whitespace-nowrap
                                    ${button.isvisible ? "block" : "hidden"}
                                    ${button.type === "green-tab" && "bg-col_c8e6c9 text-col_66bb6a"}
                                    ${button.type === "red-tab" && "bg-col_f7c4c4 text-col_ff0000"}
                                    ${button.type === "grey-tab" && "bg-col_eeeeee text-col_807e7c"}
                                    ${button.type === "current-tab" && "bg-col_eeeeee text-col_807e7c  border-2 border-col_66bb6a"}
                                    ${button.type === "red-border" && "bg-col_eeeeee text-col_e84e40  border-2  border-col_e84e40"}
                                `}
                                />
                            ))}
                        </div>
                    }
                    {headerNaviagtionBtn?.length &&
                        <div className="hidden md:flex items-center gap-2">
                            {headerNaviagtionBtn.map((btn, i) => (
                                <Button
                                    key={i}
                                    text={btn.text}
                                    prefixIcon={btn.prefixIcon}
                                    suffixIcon={btn.suffixIcon}
                                    onClick={() => btn.onClick && btn.onClick()}
                                    disabled={btn.disabled}
                                    className={`btn-default py-3 cursor-pointer
                                    ${btn.type === "white-btn" && "bg-col_ececec text-col_807e7c"}
                                    ${btn.type === "red-btn" && `suffix-btn-hover bg-col_e84e40 text-col_fff`}
                                `}
                                    suffixIconClass='btn-suffix-icon'
                                    prefixIconClass='btn-prefix-icon'
                                    authSuffixBtnIcon={btn.authSuffixBtnIcon}
                                />
                            ))}
                        </div>
                    }
                </div>
            </div>

        </header>
    )
}