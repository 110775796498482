import { edit_icon, red_delete_icon, search_plus } from 'assets';
import { Button } from 'components/button/Button';

export const adminCountry_TableHeader = [
  { key: 'name', title: 'NAME' },
  { key: 'code', title: 'CODE' },
  { key: 'currencyRatio', title: 'CURRENCY RATIO' },
  { key: 'currencyCode', title: 'CURRENCY CODE' },
  { key: 'currencySymbol', title: 'CURRENCY SYMBOL' },
  { key: 'phoneNumberCode', title: 'PHONE NUMBER CODE' },
  { key: 'contactNumber', title: 'CONTACT NUMBER' },
];

export const adminCountryCreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        name: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.name}
            </span>
          </div>
        ),
        code: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.code}
            </span>
          </div>
        ),
        currencyRatio: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.currencyRatio}
            </span>
          </div>
        ),
        currencyCode: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.currencyCode}
            </span>
          </div>
        ),
        currencySymbol: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.currencySymbol}
            </span>
          </div>
        ),
        phoneNumberCode: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.phoneNumberCode}
            </span>
          </div>
        ),
        contactNumber: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.contactNumber}
            </span>
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const adminRegion_TableHeader = [
  { key: 'code', title: 'CODE' },
  { key: 'name', title: 'NAME' },
  { key: 'others', title: '' },
];

export const adminRegionCreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        code: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.code}
            </span>
          </div>
        ),
        name: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.name}
            </span>
          </div>
        ),
        others: (
          <div className="flex items-center justify-end gap-4 pr-[1rem]">
            <img
              src={search_plus}
              alt=""
              className="cursor-pointer"
              onClick={item.others.viewItem}
            />
            <img src={edit_icon} alt="" className="cursor-pointer" />
            <img
              src={red_delete_icon}
              alt=""
              className="w-6 cursor-pointer opacity-50 hover:opacity-100"
            />
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const adminZone_TableHeader = [
  { key: 'code', title: 'CODE' },
  { key: 'name', title: 'NAME' },
  { key: 'status', title: 'STATUS ' },
  { key: 'others', title: '' },
];

export const adminZoneCreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        code: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.code}
            </span>
          </div>
        ),
        name: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.name}
            </span>
          </div>
        ),
        status: (
          <div className="max-w-[4.063rem] flex items-center justify-center bg-col_66bb6a py-2 rounded-[1rem]">
            <span
              className={`text-col_fff text-txt_087_normal capitalize py-[2px] rounded-[2rem] `}
            >
              {item.status}
            </span>
          </div>
        ),
        others: (
          <div className="flex items-center justify-end gap-4 pr-[1rem]">
            <img
              src={search_plus}
              alt=""
              className="cursor-pointer"
              onClick={item.others.viewItem}
            />
            <img src={edit_icon} alt="" className="cursor-pointer" />
            <img
              src={red_delete_icon}
              alt=""
              className="w-6 cursor-pointer opacity-50 hover:opacity-100"
            />
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const adminStation_TableHeader = [
  { key: 'code', title: 'CODE' },
  { key: 'name', title: 'NAME' },
  { key: 'status', title: 'GIGGO STATUS' },
  { key: 'superServiceCenter', title: 'SUPER SERVICE ENTER' },
  { key: 'StationPickupPrice', title: 'STATION PICKUP PRICE' },
  { key: 'isConsignable', title: 'IS CONSIGNABLE' },
  { key: 'isPublic', title: 'IS PUBLIC' },
  { key: 'isActive', title: 'IS ACTIVE' },
  { key: 'others', title: '' },
];

export const adminStationCreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        code: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.code}
            </span>
          </div>
        ),
        name: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.name}
            </span>
          </div>
        ),
        status: (
          <div className="max-w-[4.063rem] flex items-center justify-center bg-col_66bb6a py-2 rounded-[1rem]">
            <span
              className={`text-col_fff text-txt_087_normal capitalize py-[2px] rounded-[2rem] `}
            >
              {item.status}
            </span>
          </div>
        ),
        superServiceCenter: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.superServiceCenter}
            </span>
          </div>
        ),
        StationPickupPrice: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.StationPickupPrice}
            </span>
          </div>
        ),
        isConsignable: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.isConsignable}
            </span>
          </div>
        ),
        isPublic: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.isPublic}
            </span>
          </div>
        ),
        isActive: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.isActive}
            </span>
          </div>
        ),
        others: (
          <div className="flex items-center justify-end gap-4 pr-[1rem]">
            <img
              src={search_plus}
              alt=""
              className="cursor-pointer"
              onClick={item.others.viewItem}
            />
            <img src={edit_icon} alt="" className="cursor-pointer" />
            <img
              src={red_delete_icon}
              alt=""
              className="w-6 cursor-pointer opacity-50 hover:opacity-100"
            />
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};
export const adminServiceCenter_TableHeader = [
  { key: 'serviceCentreName', title: 'SC NAME' },
  { key: 'serviceCentreCode', title: 'SC CODE' },
  { key: 'status', title: 'STATUS' },
  { key: 'stationName', title: 'STATION NAME' },
  { key: 'stationAmount', title: 'STATION AMOUNT' },
  { key: 'targetAmount', title: 'TARGET AMOUNT' },
  { key: 'targetOrder', title: 'TARGET ORDER' },
  { key: 'isHub', title: 'IS HUB' },
  { key: 'isGateway', title: 'IS GATEWAY' },
  { key: 'others', title: '' },
];

export const adminServiceCenterCreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        serviceCentreName: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.serviceCentreName}
            </span>
          </div>
        ),
        serviceCentreCode: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.serviceCentreCode}
            </span>
          </div>
        ),
        status: (
          <div className="max-w-[4.063rem] flex items-center justify-center bg-col_66bb6a py-2 rounded-[1rem]">
            <span
              className={`text-col_fff text-txt_087_normal capitalize py-[2px] rounded-[2rem] `}
            >
              {item.status}
            </span>
          </div>
        ),
        stationName: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.stationName}
            </span>
          </div>
        ),
        stationAmount: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.stationAmount}
            </span>
          </div>
        ),
        targetAmount: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.targetAmount}
            </span>
          </div>
        ),
        targetOrder: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.targetOrder}
            </span>
          </div>
        ),
        others: (
          <div className="flex items-center justify-end gap-4 pr-[1rem]">
            <img
              src={search_plus}
              alt=""
              className="cursor-pointer"
              onClick={item.others.viewItem}
            />
            <img src={edit_icon} alt="" className="cursor-pointer" />
            <img
              src={red_delete_icon}
              alt=""
              className="w-6 cursor-pointer opacity-50 hover:opacity-100"
            />
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const adminStore_TableHeader = [
  { key: 'name', title: 'NAME' },
  { key: 'address', title: 'ADDRESS' },
  { key: 'city', title: 'CITY' },
  { key: 'state', title: 'STATE' },
];

export const adminStoreCreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        name: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.name}
            </span>
          </div>
        ),
        address: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.address}
            </span>
          </div>
        ),
        city: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.city}
            </span>
          </div>
        ),
        state: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.state}
            </span>
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const adminShipmentLocation_TableHeader = [
  { key: 'location', title: 'LOCATION' },
  { key: 'state', title: 'STATE' },
  { key: 'status', title: 'STATUS' },
  { key: 'eta', title: 'ETA' },
  { key: 'others', title: '' },
];

export const adminShipmentLocationCreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        location: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.location}
            </span>
          </div>
        ),
        state: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.state}
            </span>
          </div>
        ),
        status: (
          <div
            className={`${
              item?.status === 'Disabled' ? 'bg-col_e84e40' : 'bg-col_66bb6a'
            } max-w-[4.75em] flex items-center justify-center py-2 rounded-[0.5rem]`}
          >
            <span
              className={`text-col_fff text-txt_087_normal capitalize py-[2px] rounded-[2rem] `}
            >
              {item.status}
            </span>
          </div>
        ),
        homeDeliveryStatus: (
          <div
            className={`${
              item?.homeDeliveryStatus === 'Disabled'
                ? 'bg-col_e84e40'
                : 'bg-col_66bb6a'
            } max-w-[4.75em] flex items-center justify-center py-2 rounded-[0.5rem]`}
          >
            <span className={`text-txt_087_normal text-col_fff capitalize`}>
              {item.homeDeliveryStatus}
            </span>
          </div>
        ),
        others: (
          <div className="flex items-center justify-end gap-4 pr-[1rem]">
            <img
              src={search_plus}
              alt=""
              className="cursor-pointer"
              onClick={item.others.viewItem}
            />
            <img src={edit_icon} alt="" className="cursor-pointer" />
            <img
              src={red_delete_icon}
              alt=""
              className="w-6 cursor-pointer opacity-50 hover:opacity-100"
            />
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const adminPlaceLocation_TableHeader = [
  { key: 'placeLocationName', title: 'PLACE LOCATION NAME' },
  { key: 'state', title: 'STATE' },
  { key: 'stationName', title: 'STATTION NAME' },
  { key: 'isHomeDelivery', title: 'IS HOME Delivery' },
  { key: 'isNormalHD', title: 'IS NORMAL HD' },
  { key: 'isExpressHD', title: 'IS EXPRESS HD' },
  { key: 'isExtraMileDelivery', title: 'IS EXTRA MILE DELIVERY' },
  { key: 'isGIGGO', title: 'IS GIGGO' },
  { key: 'isGateway', title: 'IS GATEWAY' },
  { key: 'others', title: '' },
];

export const adminPlaceLocationCreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        placeLocationName: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.placeLocationName}
            </span>
          </div>
        ),
        state: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.state}
            </span>
          </div>
        ),
        stationName: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.stationName}
            </span>
          </div>
        ),
        isHomeDelivery: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.isHomeDelivery}
            </span>
          </div>
        ),
        isNormalHD: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.isNormalHD}
            </span>
          </div>
        ),
        isExpressHD: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.isExpressHD}
            </span>
          </div>
        ),
        isExtraMileDelivery: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.isExtraMileDelivery}
            </span>
          </div>
        ),
        isGIGGO: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.isGIGGO}
            </span>
          </div>
        ),
        isGateway: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.isGateway}
            </span>
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const adminBank_TableHeader = [
  { key: 'bankName', title: 'BANK NAME' },
  { key: 'others', title: '' },
];

export const adminBankCreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        bankName: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.bankName}
            </span>
          </div>
        ),

        others: (
          <div className="flex items-center justify-end gap-4 pr-[1rem]">
            <img
              src={search_plus}
              alt=""
              className="cursor-pointer"
              onClick={item.others.viewItem}
            />
            <img src={edit_icon} alt="" className="cursor-pointer" />
            <img
              src={red_delete_icon}
              alt=""
              className="w-6 cursor-pointer opacity-50 hover:opacity-100"
            />
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const adminRouteZoneMapping_TableHeader = [
  { key: 'departureStation', title: 'DEPARTURE STATION' },
  { key: 'destinationStation', title: 'DESTINATION STATION' },
  { key: 'zone', title: 'ZONE' },
  { key: 'status', title: 'STATUS' },
  { key: 'eta', title: 'ETA' },
  { key: 'others', title: '' },
];

export const adminRouteZoneMappingCreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        departureStation: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.departureStation}
            </span>
          </div>
        ),
        destinationStation: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.destinationStation}
            </span>
          </div>
        ),
        zone: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.zone}
            </span>
          </div>
        ),
        status: (
          <div
            className={`${
              item?.status === 'Disabled' ? 'bg-col_e84e40' : 'bg-col_66bb6a'
            } max-w-[4.75em] flex items-center justify-center py-2 rounded-[0.5rem]`}
          >
            <span
              className={`text-col_fff text-txt_087_normal capitalize py-[2px] rounded-[2rem] `}
            >
              {item.status}
            </span>
          </div>
        ),
        eta: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.eta}
            </span>
          </div>
        ),
        others: (
          <div className="flex items-center justify-end gap-4 pr-[1rem]">
            <img
              src={search_plus}
              alt=""
              className="cursor-pointer"
              onClick={item.others.viewItem}
            />
            <img src={edit_icon} alt="" className="cursor-pointer" />
            <img
              src={red_delete_icon}
              alt=""
              className="w-6 cursor-pointer opacity-50 hover:opacity-100"
            />
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const adminDeliveryOption_TableHeader = [
  { key: 'code', title: 'CODE' },
  { key: 'description', title: 'DESCRIPTION' },
  { key: 'type', title: 'TYPE' },
  { key: 'status', title: 'STATUS' },
  { key: 'others', title: '' },
];

export const adminDeliveryOptionCreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        code: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.code}
            </span>
          </div>
        ),
        description: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.description}
            </span>
          </div>
        ),
        type: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.type}
            </span>
          </div>
        ),
        status: (
          <div
            className={`${
              item?.status === 'Disabled' ? 'bg-col_e84e40' : 'bg-col_66bb6a'
            } max-w-[4.75em] flex items-center justify-center py-2 rounded-[0.5rem]`}
          >
            <span
              className={`text-col_fff text-txt_087_normal capitalize py-[2px] rounded-[2rem] `}
            >
              {item.status}
            </span>
          </div>
        ),
        others: (
          <div className="flex items-center justify-end gap-4 pr-[1rem]">
            <img
              src={search_plus}
              alt=""
              className="cursor-pointer"
              onClick={item.others.viewItem}
            />
            <img src={edit_icon} alt="" className="cursor-pointer" />
            <img
              src={red_delete_icon}
              alt=""
              className="w-6 cursor-pointer opacity-50 hover:opacity-100"
            />
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const adminDeliveryOptionPrices_TableHeader = [
  { key: 'deliveryOption', title: 'DELIVERY OPTION' },
  { key: 'description', title: 'DESCRIPTION' },
  { key: 'zone', title: 'ZONE' },
  { key: 'price', title: 'PRICE' },
  { key: 'country', title: 'COUNTRY' },
  { key: 'others', title: '' },
];

export const adminDeliveryOptionPricesCreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        deliveryOption: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.deliveryOption}
            </span>
          </div>
        ),
        description: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.description}
            </span>
          </div>
        ),
        zone: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.zone}
            </span>
          </div>
        ),
        price: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.price}
            </span>
          </div>
        ),
        country: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.country}
            </span>
          </div>
        ),
        others: (
          <div className="flex items-center justify-end gap-4 pr-[1rem]">
            <img
              src={search_plus}
              alt=""
              className="cursor-pointer"
              onClick={item.others.viewItem}
            />
            <img src={edit_icon} alt="" className="cursor-pointer" />
            <img
              src={red_delete_icon}
              alt=""
              className="w-6 cursor-pointer opacity-50 hover:opacity-100"
            />
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const adminCountryRouteZoneMapping_TableHeader = [
  { key: 'departureStation', title: 'DEPARTURE STATION' },
  { key: 'destinationStation', title: 'DESTINATION STATION' },
  { key: 'zone', title: 'ZONE' },
  { key: 'rate', title: 'RATE' },
  { key: 'type', title: 'TYPE' },
  { key: 'status', title: 'STATUS' },
  { key: 'others', title: '' },
];

export const adminCountryRouteZoneMappingCreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        departureStation: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.departureStation}
            </span>
          </div>
        ),
        destinationStation: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.destinationStation}
            </span>
          </div>
        ),
        zone: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.zone}
            </span>
          </div>
        ),
        rate: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.rate}
            </span>
          </div>
        ),
        type: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.type}
            </span>
          </div>
        ),
        status: (
          <div
            className={`${
              item?.status === 'Disabled' ? 'bg-col_e84e40' : 'bg-col_66bb6a'
            } max-w-[4.75em] flex items-center justify-center py-2 rounded-[0.5rem]`}
          >
            <span
              className={`text-col_fff text-txt_087_normal capitalize py-[2px] rounded-[2rem] `}
            >
              {item.status}
            </span>
          </div>
        ),
        others: (
          <div className="flex items-center justify-end gap-4 pr-[1rem]">
            <img
              src={search_plus}
              alt=""
              className="cursor-pointer"
              onClick={item.others.viewItem}
            />
            <img src={edit_icon} alt="" className="cursor-pointer" />
            <img
              src={red_delete_icon}
              alt=""
              className="w-6 cursor-pointer opacity-50 hover:opacity-100"
            />
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const adminDeliveryLocationPrices_TableHeader = [
  { key: 'location', title: 'LOCATION' },
  { key: 'price', title: 'PRICE' },
  { key: 'others', title: '' },
];

export const adminDeliveryLocationPricesCreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        location: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.location}
            </span>
          </div>
        ),
        price: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.price}
            </span>
          </div>
        ),
        others: (
          <div className="flex items-center justify-end gap-4 pr-[1rem]">
            <img
              src={search_plus}
              alt=""
              className="cursor-pointer"
              onClick={item.others.viewItem}
            />
            <img src={edit_icon} alt="" className="cursor-pointer" />
            <img
              src={red_delete_icon}
              alt=""
              className="w-6 cursor-pointer opacity-50 hover:opacity-100"
            />
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const adminOptionRegionSCmapping_TableHeader = [
  { key: 'regionName', title: 'REGION NAME' },
  { key: 'serviceCentres', title: 'SERVICE CENTRES' },
  { key: 'others', title: '' },
];

export const adminRegionSCmappingCreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        regionName: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.regionName}
            </span>
          </div>
        ),
        serviceCentres: (
          <div className="flex items-center">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.serviceCentres}
            </span>
          </div>
        ),
        others: (
          <div className="flex items-center justify-end gap-4 pr-[1rem]">
            <img
              src={search_plus}
              alt=""
              className="cursor-pointer"
              onClick={item.others.viewItem}
            />
            <img src={edit_icon} alt="" className="cursor-pointer" />
            <img
              src={red_delete_icon}
              alt=""
              className="w-6 cursor-pointer opacity-50 hover:opacity-100"
            />
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const adminSpecialDelivery_TableHeader = [
  { key: 'name', title: 'NAME' },
  { key: 'status', title: 'STATUS' },
  { key: 'weight', title: 'WEIGHT' },
  { key: 'type', title: 'TYPE' },
  { key: 'others', title: '' },
];

export const adminSpecialDeliveryCreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        name: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.name}
            </span>
          </div>
        ),
        status: (
          <div
            className={`${
              item?.status === 'Disabled' ? 'bg-col_e84e40' : 'bg-col_66bb6a'
            } max-w-[4.75em] flex items-center justify-center py-2 rounded-[0.5rem]`}
          >
            <span
              className={`text-col_fff text-txt_087_normal capitalize py-[2px] rounded-[2rem] `}
            >
              {item.status}
            </span>
          </div>
        ),
        weight: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.weight}
            </span>
          </div>
        ),
        type: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.type}
            </span>
          </div>
        ),
        others: (
          <div className="flex items-center justify-end gap-4 pr-[1rem]">
            <img
              src={search_plus}
              alt=""
              className="cursor-pointer"
              onClick={item.others.viewItem}
            />
            <img src={edit_icon} alt="" className="cursor-pointer" />
            <img
              src={red_delete_icon}
              alt=""
              className="w-6 cursor-pointer opacity-50 hover:opacity-100"
            />
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const adminSpecialZonePrices_TableHeader = [
  { key: 'specialPackageName', title: 'SPECIAL PACKAGE NAME' },
  { key: 'weight', title: 'WEIGHT (KG)' },
  { key: 'zoneName', title: 'ZONE NAME' },
  { key: 'price', title: 'PRICE' },
  { key: 'country', title: 'COUNTRY' },
  { key: 'others', title: '' },
];

export const adminSpecialZonePricesCreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        specialPackageName: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.specialPackageName}
            </span>
          </div>
        ),
        weight: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.weight}
            </span>
          </div>
        ),
        zoneName: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.zoneName}
            </span>
          </div>
        ),
        price: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.price}
            </span>
          </div>
        ),
        country: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.country}
            </span>
          </div>
        ),
        others: (
          <div className="flex items-center justify-end gap-4 pr-[1rem]">
            <img
              src={search_plus}
              alt=""
              className="cursor-pointer"
              onClick={item.others.viewItem}
            />
            <img src={edit_icon} alt="" className="cursor-pointer" />
            <img
              src={red_delete_icon}
              alt=""
              className="w-6 cursor-pointer opacity-50 hover:opacity-100"
            />
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const adminRegularZonePrices_TableHeader = [
  { key: 'weight', title: 'WEIGHT (KG)' },
  { key: 'zoneName', title: 'ZONE NAME' },
  { key: 'price', title: 'PRICE' },
  { key: 'type', title: 'TYPE' },
  { key: 'country', title: 'COUNTRY' },
  { key: 'others', title: '' },
];

export const adminRegularZonePricesCreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        weight: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.weight}
            </span>
          </div>
        ),
        zoneName: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.zoneName}
            </span>
          </div>
        ),
        price: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.price}
            </span>
          </div>
        ),
        type: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.type}
            </span>
          </div>
        ),
        country: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.country}
            </span>
          </div>
        ),
        others: (
          <div className="flex items-center justify-end gap-4 pr-[1rem]">
            <img
              src={search_plus}
              alt=""
              className="cursor-pointer"
              onClick={item.others.viewItem}
            />
            <img src={edit_icon} alt="" className="cursor-pointer" />
            <img
              src={red_delete_icon}
              alt=""
              className="w-6 cursor-pointer opacity-50 hover:opacity-100"
            />
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const adminPricecategory_TableHeader = [
  { key: 'name', title: 'NAME' },
  { key: 'categoryMinWeight', title: 'CATEGORY MIN WEIGHT' },
  { key: 'categoryMinPrice', title: 'CATEGORY MIN PRICE' },
  { key: 'subMinWeight', title: 'SUB MIN WEIGHT' },
  { key: 'subMinPrice', title: 'SUB MIN PRICE' },
  { key: 'subMinPrice', title: 'SUB MIN PRICE' },
  { key: 'subMinPrice', title: 'SUB MIN PRICE' },
  { key: 'country', title: 'COUNTRY' },
  { key: 'departureCountry', title: 'DEPARTURE COUNTRY' },
  { key: 'others', title: '' },
];

export const adminPricecategoryCreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        name: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.name}
            </span>
          </div>
        ),
        categoryMinWeight: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.categoryMinWeight}
            </span>
          </div>
        ),
        categoryMinPrice: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.categoryMinPrice}
            </span>
          </div>
        ),
        subMinWeight: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.subMinWeight}
            </span>
          </div>
        ),
        subMinPrice: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.subMinWeight}
            </span>
          </div>
        ),
        pricePerWeight: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.subMinWeight}
            </span>
          </div>
        ),
        country: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.country}
            </span>
          </div>
        ),
        departureCountry: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.departureCountry}
            </span>
          </div>
        ),
        others: (
          <div className="flex items-center justify-end gap-4 pr-[1rem]">
            <img
              src={search_plus}
              alt=""
              className="cursor-pointer"
              onClick={item.others.viewItem}
            />
            <img src={edit_icon} alt="" className="cursor-pointer" />
            <img
              src={red_delete_icon}
              alt=""
              className="w-6 cursor-pointer opacity-50 hover:opacity-100"
            />
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const adminHaulage_TableHeader = [
  { key: 'ton', title: 'TONNE(TONS)' },
  { key: 'status', title: 'STATUS' },
  { key: 'fixedRate', title: 'FIXED RATE' },
  { key: 'additionalRate', title: 'ADDITIONAL RATE' },
  { key: 'others', title: '' },
];

export const adminHaulageCreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        ton: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.ton}
            </span>
          </div>
        ),
        status: (
          <div
            className={`${
              item?.status === 'Inactive' ? 'bg-col_e84e40' : 'bg-col_66bb6a'
            } max-w-[4.75em] flex items-center justify-center py-2 rounded-[0.5rem]`}
          >
            <span
              className={`text-col_fff text-txt_087_normal capitalize py-[2px] rounded-[2rem] `}
            >
              {item.status}
            </span>
          </div>
        ),
        fixedRate: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.fixedRate}
            </span>
          </div>
        ),
        additionalRate: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.additionalRate}
            </span>
          </div>
        ),
        others: (
          <div className="flex items-center justify-end gap-4 pr-[1rem]">
            <img
              src={search_plus}
              alt=""
              className="cursor-pointer"
              onClick={item.others.viewItem}
            />
            <img src={edit_icon} alt="" className="cursor-pointer" />
            <img
              src={red_delete_icon}
              alt=""
              className="w-6 cursor-pointer opacity-50 hover:opacity-100"
            />
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const adminHaulageDistance_TableHeader = [
  { key: 'distance', title: 'DISTANCE' },
  { key: 'status', title: 'STATUS' },
  { key: 'destination', title: 'DESTINATION' },
  { key: 'departure', title: 'DEPARTURE' },
  { key: 'others', title: '' },
];

export const adminHaulageDistanceCreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        distance: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.distance}
            </span>
          </div>
        ),
        destination: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.destination}
            </span>
          </div>
        ),
        departure: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.departure}
            </span>
          </div>
        ),
        status: (
          <div
            className={`${
              item?.status === 'Inactive' ? 'bg-col_e84e40' : 'bg-col_66bb6a'
            } max-w-[4.75em] flex items-center justify-center py-2 rounded-[0.5rem]`}
          >
            <span
              className={`text-col_fff text-txt_087_normal capitalize py-[2px] rounded-[2rem] `}
            >
              {item.status}
            </span>
          </div>
        ),
        others: (
          <div className="flex items-center justify-end gap-4 pr-[1rem]">
            <img
              src={search_plus}
              alt=""
              className="cursor-pointer"
              onClick={item.others.viewItem}
            />
            <img src={edit_icon} alt="" className="cursor-pointer" />
            <img
              src={red_delete_icon}
              alt=""
              className="w-6 cursor-pointer opacity-50 hover:opacity-100"
            />
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const adminHaulagePrice_TableHeader = [
  { key: 'name', title: 'NAME' },
  { key: 'value', title: 'VALUE' },
  { key: 'status', title: 'STATUS' },
  { key: 'country', title: 'COUNTRY' },
  { key: 'others', title: '' },
];

export const adminHaulagePriceCreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        name: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.name}
            </span>
          </div>
        ),
        value: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.value}
            </span>
          </div>
        ),
        country: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.country}
            </span>
          </div>
        ),
        status: (
          <div
            className={`${
              item?.status === 'Inactive' ? 'bg-col_e84e40' : 'bg-col_66bb6a'
            } max-w-[4.75em] flex items-center justify-center py-2 rounded-[0.5rem]`}
          >
            <span
              className={`text-col_fff text-txt_087_normal capitalize py-[2px] rounded-[2rem] `}
            >
              {item.status}
            </span>
          </div>
        ),
        others: (
          <div className="flex items-center justify-end gap-4 pr-[1rem]">
            <img
              src={search_plus}
              alt=""
              className="cursor-pointer"
              onClick={item.others.viewItem}
            />
            <img src={edit_icon} alt="" className="cursor-pointer" />
            <img
              src={red_delete_icon}
              alt=""
              className="w-6 cursor-pointer opacity-50 hover:opacity-100"
            />
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const adminVAT_TableHeader = [
  { key: 'name', title: 'NAME' },
  { key: 'value', title: 'VALUE' },
  { key: 'status', title: 'STATUS' },
  { key: 'country', title: 'COUNTRY' },
  { key: 'others', title: '' },
];

export const adminVATCreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        name: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.name}
            </span>
          </div>
        ),
        value: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.value}
            </span>
          </div>
        ),
        country: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.country}
            </span>
          </div>
        ),
        status: (
          <div
            className={`${
              item?.status === 'Inactive' ? 'bg-col_e84e40' : 'bg-col_66bb6a'
            } max-w-[4.75em] flex items-center justify-center py-2 rounded-[0.5rem]`}
          >
            <span
              className={`text-col_fff text-txt_087_normal capitalize py-[2px] rounded-[2rem] `}
            >
              {item.status}
            </span>
          </div>
        ),
        others: (
          <div className="flex items-center justify-end gap-4 pr-[1rem]">
            <img
              src={search_plus}
              alt=""
              className="cursor-pointer"
              onClick={item.others.viewItem}
            />
            <img src={edit_icon} alt="" className="cursor-pointer" />
            <img
              src={red_delete_icon}
              alt=""
              className="w-6 cursor-pointer opacity-50 hover:opacity-100"
            />
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const adminInsurance_TableHeader = [
  { key: 'name', title: 'NAME' },
  { key: 'value', title: 'VALUE' },
  { key: 'status', title: 'STATUS' },
  { key: 'country', title: 'COUNTRY' },
  { key: 'others', title: '' },
];

export const adminInsuranceCreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        name: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.name}
            </span>
          </div>
        ),
        value: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.value}
            </span>
          </div>
        ),
        country: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.country}
            </span>
          </div>
        ),
        status: (
          <div
            className={`${
              item?.status === 'Inactive' ? 'bg-col_e84e40' : 'bg-col_66bb6a'
            } max-w-[4.75em] flex items-center justify-center py-2 rounded-[0.5rem]`}
          >
            <span
              className={`text-col_fff text-txt_087_normal capitalize py-[2px] rounded-[2rem] `}
            >
              {item.status}
            </span>
          </div>
        ),
        others: (
          <div className="flex items-center justify-end gap-4 pr-[1rem]">
            <img
              src={search_plus}
              alt=""
              className="cursor-pointer"
              onClick={item.others.viewItem}
            />
            <img src={edit_icon} alt="" className="cursor-pointer" />
            <img
              src={red_delete_icon}
              alt=""
              className="w-6 cursor-pointer opacity-50 hover:opacity-100"
            />
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const adminShipmentPackagePrice_TableHeader = [
  { key: 'description', title: 'DESCRIPTION' },
  { key: 'price', title: 'PRICE' },
  { key: 'country', title: 'COUNTRY' },
  { key: 'others', title: '' },
];

export const adminShipmentPackagePriceCreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        description: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.description}
            </span>
          </div>
        ),
        price: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.price}
            </span>
          </div>
        ),
        country: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.country}
            </span>
          </div>
        ),
        others: (
          <div className="flex items-center justify-end gap-4 pr-[1rem]">
            <img
              src={search_plus}
              alt=""
              className="cursor-pointer"
              onClick={item.others.viewItem}
            />
            <img src={edit_icon} alt="" className="cursor-pointer" />
            <img
              src={red_delete_icon}
              alt=""
              className="w-6 cursor-pointer opacity-50 hover:opacity-100"
            />
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const adminWeightLimit_TableHeader = [
  { key: 'weightLimit', title: 'WEIGHT LIMIT (KG)' },
  { key: 'status', title: 'STATUS' },
  { key: 'others', title: '' },
];

export const adminWeightLimitCreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        weightLimit: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.weightLimit}
            </span>
          </div>
        ),
        status: (
          <div
            className={`${
              item?.status === 'Inactive' ? 'bg-col_e84e40' : 'bg-col_66bb6a'
            } max-w-[4.75em] flex items-center justify-center py-2 rounded-[0.5rem]`}
          >
            <span
              className={`text-col_fff text-txt_087_normal capitalize py-[2px] rounded-[2rem] `}
            >
              {item.status}
            </span>
          </div>
        ),
        others: (
          <div className="flex items-center justify-end gap-4 pr-[1rem]">
            <img
              src={search_plus}
              alt=""
              className="cursor-pointer"
              onClick={item.others.viewItem}
            />
            <img src={edit_icon} alt="" className="cursor-pointer" />
            <img
              src={red_delete_icon}
              alt=""
              className="w-6 cursor-pointer opacity-50 hover:opacity-100"
            />
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const adminWeightLimitPrices_TableHeader = [
  { key: 'additionalWeight', title: 'ADDITIONAL WEIGHT (KG)' },
  { key: 'zone', title: 'ZONE' },
  { key: 'price', title: 'PRICE' },
  { key: 'type', title: 'TYPE' },
  { key: 'country', title: 'COUNTRY' },
  { key: 'others', title: '' },
];

export const adminWeightLimitPricesCreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        additionalWeight: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.additionalWeight}
            </span>
          </div>
        ),
        zone: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.zone}
            </span>
          </div>
        ),
        price: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.price}
            </span>
          </div>
        ),
        type: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.type}
            </span>
          </div>
        ),
        country: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.country}
            </span>
          </div>
        ),
        others: (
          <div className="flex items-center justify-end gap-4 pr-[1rem]">
            <img
              src={search_plus}
              alt=""
              className="cursor-pointer"
              onClick={item.others.viewItem}
            />
            <img src={edit_icon} alt="" className="cursor-pointer" />
            <img
              src={red_delete_icon}
              alt=""
              className="w-6 cursor-pointer opacity-50 hover:opacity-100"
            />
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const adminEmail_TableHeader = [
  { key: 'subject', title: 'SUBJECT' },
  { key: 'body', title: 'BODY' },
  { key: 'from', title: 'FROM' },
  { key: 'to', title: 'TO' },
  { key: 'messageType', title: 'MSG TYPE' },
  { key: 'others', title: '' },
];

export const adminEmailCreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        subject: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.subject}
            </span>
          </div>
        ),
        body: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.body}
            </span>
          </div>
        ),
        from: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.from}
            </span>
          </div>
        ),
        to: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.to}
            </span>
          </div>
        ),
        mesageType: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.mesageType}
            </span>
          </div>
        ),
        others: (
          <div className="flex items-center justify-end gap-4 pr-[1rem]">
            <img src={edit_icon} alt="" className="cursor-pointer" />
            <img
              src={red_delete_icon}
              alt=""
              className="w-6 cursor-pointer opacity-50 hover:opacity-100"
            />
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const adminSMS_TableHeader = [
  { key: 'subject', title: 'SUBJECT' },
  { key: 'body', title: 'BODY' },
  { key: 'from', title: 'FROM' },
  { key: 'to', title: 'TO' },
  { key: 'smsPlatform', title: 'SMS PLATFORM' },
  { key: 'others', title: '' },
];

export const adminSMSCreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        subject: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.subject}
            </span>
          </div>
        ),
        body: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.body}
            </span>
          </div>
        ),
        from: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.from}
            </span>
          </div>
        ),
        to: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.to}
            </span>
          </div>
        ),
        smsPlatform: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.smsPlatform}
            </span>
          </div>
        ),
        others: (
          <div className="flex items-center justify-end gap-4 pr-[1rem]">
            <img src={edit_icon} alt="" className="cursor-pointer" />
            <img
              src={red_delete_icon}
              alt=""
              className="w-6 cursor-pointer opacity-50 hover:opacity-100"
            />
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const adminSMSsendLog_TableHeader = [
  { key: 'date', title: 'DATE' },
  { key: 'from', title: 'FROM' },
  { key: 'to', title: 'TO' },
  { key: 'result', title: 'RESULT' },
  { key: 'status', title: 'STATUS' },
  { key: 'description', title: 'DESCRIPTION' },
  { key: 'message', title: 'MESSAGE' },
  { key: 'others', title: '' },
];

export const adminSMSsendLogCreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        date: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.date}
            </span>
          </div>
        ),
        from: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.from}
            </span>
          </div>
        ),
        to: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.to}
            </span>
          </div>
        ),
        result: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.result}
            </span>
          </div>
        ),
        status: (
          <div
            className={`${
              item?.status === 'Inactive' ? 'bg-col_e84e40' : 'bg-col_66bb6a'
            } max-w-[4.75em] flex items-center justify-center py-2 rounded-[0.5rem]`}
          >
            <span
              className={`text-col_fff text-txt_087_normal capitalize py-[2px] rounded-[2rem] `}
            >
              {item.status}
            </span>
          </div>
        ),
        description: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.description}
            </span>
          </div>
        ),
        message: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.message}
            </span>
          </div>
        ),
        others: (
          <div className="flex items-center justify-end gap-4 pr-[1rem]">
            <img
              src={search_plus}
              alt=""
              className="cursor-pointer"
              onClick={item.others.viewItem}
            />
            <img
              src={red_delete_icon}
              alt=""
              className="w-6 cursor-pointer opacity-50 hover:opacity-100"
            />
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const adminEmailSendLog_TableHeader = [
  { key: 'date', title: 'DATE' },
  { key: 'from', title: 'FROM' },
  { key: 'to', title: 'TO' },
  { key: 'status', title: 'STATUS' },
  { key: 'message', title: 'MESSAGE' },
  { key: 'others', title: '' },
];

export const adminEmailSendLogCreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        date: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.date}
            </span>
          </div>
        ),
        from: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.from}
            </span>
          </div>
        ),
        to: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.to}
            </span>
          </div>
        ),
        status: (
          <div
            className={`${
              item?.status === 'Inactive' ? 'bg-col_e84e40' : 'bg-col_66bb6a'
            } max-w-[4.75em] flex items-center justify-center py-2 rounded-[0.5rem]`}
          >
            <span
              className={`text-col_fff text-txt_087_normal capitalize py-[2px] rounded-[2rem] `}
            >
              {item.status}
            </span>
          </div>
        ),
        message: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.message}
            </span>
          </div>
        ),
        others: (
          <div className="flex items-center justify-end gap-4 pr-[1rem]">
            <img
              src={search_plus}
              alt=""
              className="cursor-pointer"
              onClick={item.others.viewItem}
            />
            <img
              src={red_delete_icon}
              alt=""
              className="w-6 cursor-pointer opacity-50 hover:opacity-100"
            />
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const adminNotification_TableHeader = [
  { key: 'subject', title: 'SUBJECT' },
  { key: 'message', title: 'MESSAGE' },
  { key: 'others', title: '' },
];

export const adminNotificationCreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        subject: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.subject}
            </span>
          </div>
        ),
        message: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.message}
            </span>
          </div>
        ),
        others: (
          <div className="flex items-center justify-end gap-4 pr-[1rem]">
            <img
              src={search_plus}
              alt=""
              className="cursor-pointer"
              onClick={item.others.viewItem}
            />
            <img src={edit_icon} alt="" className="cursor-pointer" />
            <img
              src={red_delete_icon}
              alt=""
              className="w-6 cursor-pointer opacity-50 hover:opacity-100"
            />
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const adminSLA_TableHeader = [
  { key: 'type', title: 'TYPE' },
  { key: 'body', title: 'BODY' },
  { key: 'others', title: '' },
];

export const adminSLACreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        type: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.type}
            </span>
          </div>
        ),
        body: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.body}
            </span>
          </div>
        ),
        others: (
          <div className="flex items-center justify-end gap-4 pr-[1rem]">
            <img
              src={search_plus}
              alt=""
              className="cursor-pointer"
              onClick={item.others.viewItem}
            />
            <img src={edit_icon} alt="" className="cursor-pointer" />
            <img
              src={red_delete_icon}
              alt=""
              className="w-6 cursor-pointer opacity-50 hover:opacity-100"
            />
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const adminScanStatus_TableHeader = [
  { key: 'incident', title: 'INCIDENT' },
  { key: 'code', title: 'CODE' },
  { key: 'scanReason', title: 'SCAN REASON' },
  { key: 'comment', title: 'COMMENT' },
  { key: 'hiddenFlag', title: 'HIDDEN FLAG' },
  { key: 'others', title: '' },
];

export const adminScanStatusCreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        incident: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.incident}
            </span>
          </div>
        ),
        code: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.code}
            </span>
          </div>
        ),
        scanReason: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.scanReason}
            </span>
          </div>
        ),
        comment: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.comment}
            </span>
          </div>
        ),
        hiddelFlag: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.hiddelFlag}
            </span>
          </div>
        ),
        others: (
          <div className="flex items-center justify-end gap-4 pr-[1rem]">
            <img
              src={search_plus}
              alt=""
              className="cursor-pointer"
              onClick={item.others.viewItem}
            />
            <img src={edit_icon} alt="" className="cursor-pointer" />
            <img
              src={red_delete_icon}
              alt=""
              className="w-6 cursor-pointer opacity-50 hover:opacity-100"
            />
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const adminLogVisitReason_TableHeader = [
  { key: 'message', title: 'MESSAGE' },
  { key: 'others', title: '' },
];

export const adminLogVisitReasonCreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        message: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.message}
            </span>
          </div>
        ),
        others: (
          <div className="flex items-center justify-end gap-4 pr-[1rem]">
            <img
              src={search_plus}
              alt=""
              className="cursor-pointer"
              onClick={item.others.viewItem}
            />
            <img src={edit_icon} alt="" className="cursor-pointer" />
            <img
              src={red_delete_icon}
              alt=""
              className="w-6 cursor-pointer opacity-50 hover:opacity-100"
            />
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const adminGlobalProperty_TableHeader = [
  { key: 'key', title: 'KEY' },
  { key: 'value', title: 'VALUE' },
  { key: 'description', title: 'DESCRIPTION' },
  { key: 'country', title: 'COUNTRY' },
  { key: 'status', title: 'STATUS' },
  { key: 'others', title: '' },
];

export const adminGlobalPropertyCreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        key: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.key}
            </span>
          </div>
        ),
        value: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.value}
            </span>
          </div>
        ),
        description: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.description}
            </span>
          </div>
        ),
        country: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.country}
            </span>
          </div>
        ),
        status: (
          <div
            className={`${
              item?.status === '' ? '' : ''
            } max-w-[4.75em] flex items-center justify-center py-2 rounded-[0.5rem]`}
          >
            <span
              className={`text-col_fff text-txt_087_normal capitalize py-[2px] rounded-[2rem] `}
            >
              {item.status}
            </span>
          </div>
        ),
        others: (
          <div className="flex items-center justify-end gap-4 pr-[1rem]">
            <img
              src={search_plus}
              alt=""
              className="cursor-pointer"
              onClick={item.others.viewItem}
            />
            <img src={edit_icon} alt="" className="cursor-pointer" />
            <img
              src={red_delete_icon}
              alt=""
              className="w-6 cursor-pointer opacity-50 hover:opacity-100"
            />
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const adminExpenseType_TableHeader = [
  { key: 'name', title: 'NAME' },
  { key: 'others', title: '' },
];

export const adminExpenseTypeCreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        name: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.name}
            </span>
          </div>
        ),
        others: (
          <div className="flex items-center justify-end gap-4 pr-[1rem]">
            <img
              src={search_plus}
              alt=""
              className="cursor-pointer"
              onClick={item.others.viewItem}
            />
            <img src={edit_icon} alt="" className="cursor-pointer" />
            <img
              src={red_delete_icon}
              alt=""
              className="w-6 cursor-pointer opacity-50 hover:opacity-100"
            />
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const adminPartners_TableHeader = [
  { key: 'partnerType', title: 'PARTNER TYPE' },
  { key: 'companyName', title: 'COMPANY NAME' },
  { key: 'code', title: 'CODE' },
  { key: 'email', title: 'EMAIL' },
  { key: 'phoneNumber', title: 'PHONE NUMBER' },
  { key: 'others', title: '' },
];

export const adminPartnersCreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        partnerType: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.partnerType}
            </span>
          </div>
        ),
        companyName: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.companyName}
            </span>
          </div>
        ),
        code: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.code}
            </span>
          </div>
        ),
        email: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.email}
            </span>
          </div>
        ),
        phoneNumber: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.email}
            </span>
          </div>
        ),
        others: (
          <div className="flex items-center justify-end gap-4 pr-[1rem]">
            <img
              src={search_plus}
              alt=""
              className="cursor-pointer"
              onClick={item.others.viewItem}
            />
            <img src={edit_icon} alt="" className="cursor-pointer" />
            <img
              src={red_delete_icon}
              alt=""
              className="w-6 cursor-pointer opacity-50 hover:opacity-100"
            />
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const adminFleetMake_TableHeader = [
  { key: 'name', title: 'NAME' },
  { key: 'others', title: '' },
];

export const adminFleetMakeCreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        name: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.name}
            </span>
          </div>
        ),
        others: (
          <div className="flex items-center justify-end gap-4 pr-[1rem]">
            <img
              src={search_plus}
              alt=""
              className="cursor-pointer"
              onClick={item.others.viewItem}
            />
            <img src={edit_icon} alt="" className="cursor-pointer" />
            <img
              src={red_delete_icon}
              alt=""
              className="w-6 cursor-pointer opacity-50 hover:opacity-100"
            />
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const adminFleetModel_TableHeader = [
  { key: 'modelName', title: 'MODEL NAME' },
  { key: 'makeName', title: 'MAKE NAME' },
  { key: 'others', title: '' },
];

export const adminFleetModelCreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        modelName: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.modelName}
            </span>
          </div>
        ),
        makeName: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.makeName}
            </span>
          </div>
        ),
        others: (
          <div className="flex items-center justify-end gap-4 pr-[1rem]">
            <img
              src={search_plus}
              alt=""
              className="cursor-pointer"
              onClick={item.others.viewItem}
            />
            <img src={edit_icon} alt="" className="cursor-pointer" />
            <img
              src={red_delete_icon}
              alt=""
              className="w-6 cursor-pointer opacity-50 hover:opacity-100"
            />
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const adminFleets_TableHeader = [
  { key: 'registrationNumber', title: 'REGISTRATION NUMBER' },
  { key: 'model', title: 'MODEL' },
  { key: 'type', title: 'TYPE' },
  { key: 'capacity', title: 'CAPACITY' },
  { key: 'status', title: 'STATUS' },
  { key: 'others', title: '' },
];

export const adminFleetsCreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        registrationNumber: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.registrationNumber}
            </span>
          </div>
        ),
        model: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.model}
            </span>
          </div>
        ),
        type: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.type}
            </span>
          </div>
        ),
        capacity: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.capacity}
            </span>
          </div>
        ),
        status: (
          <div
            className={`${
              item?.status === '' ? '' : ''
            } max-w-[4.75em] flex items-center justify-center py-2 rounded-[0.5rem]`}
          >
            <span
              className={`text-col_fff text-txt_087_normal capitalize py-[2px] rounded-[2rem] `}
            >
              {item.status}
            </span>
          </div>
        ),
        others: (
          <div className="flex items-center justify-end gap-4 pr-[1rem]">
            <img
              src={search_plus}
              alt=""
              className="cursor-pointer"
              onClick={item.others.viewItem}
            />
            <img src={edit_icon} alt="" className="cursor-pointer" />
            <img
              src={red_delete_icon}
              alt=""
              className="w-6 cursor-pointer opacity-50 hover:opacity-100"
            />
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const adminIndividualCustomer_TableHeader = [
  { key: 'code', title: 'CODE' },
  { key: 'fullName', title: 'FULL NAME' },
  { key: 'phoneNumber', title: 'PHONE NUMBER' },
  { key: 'email', title: 'EMAIL' },
  { key: 'gender', title: 'GENDER' },
  { key: 'userCountry', title: 'USER COUNTRY' },
  { key: 'others', title: '' },
];

export const adminIndividualCustomerCreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        code: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.code}
            </span>
          </div>
        ),
        fullName: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.fullName}
            </span>
          </div>
        ),
        phoneNumber: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.phoneNumber}
            </span>
          </div>
        ),
        email: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.email}
            </span>
          </div>
        ),
        gender: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.gender}
            </span>
          </div>
        ),
        userCountry: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.userCountry}
            </span>
          </div>
        ),
        others: (
          <div className="flex items-center justify-end gap-4 pr-[1rem]">
            <img
              src={search_plus}
              alt=""
              className="cursor-pointer"
              onClick={item.others.viewItem}
            />
            <img src={edit_icon} alt="" className="cursor-pointer" />
            <img
              src={red_delete_icon}
              alt=""
              className="w-6 cursor-pointer opacity-50 hover:opacity-100"
            />
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const adminAccountCustomer_TableHeader = [
  { key: 'code', title: 'CODE' },
  { key: 'merchantName', title: 'MERCHANT NAME' },
  { key: 'phoneNumber', title: 'PHONE NUMBER' },
  { key: 'email', title: 'EMAIL' },
  { key: 'type', title: 'TYPE' },
  { key: 'status', title: 'STATUS' },
  { key: 'discount', title: 'DISCOUNT' },
  { key: 'settlementPeriod', title: 'SETTLEMENT PERIOD' },
  { key: 'balance', title: 'BALANCE' },
  { key: 'address', title: 'ADDRESS' },
  { key: 'city', title: 'CITY' },
  { key: 'state', title: 'STATE' },
  { key: 'returnOption', title: 'RETURN OPTION' },
  { key: 'returnServiceCentre', title: 'RETURN SERVICE CENTRE' },
  { key: 'returnAddress', title: 'RETURN ADDRESS' },
  { key: 'country', title: 'COUNTRY' },
  { key: 'date', title: 'DATE' },
  { key: 'rcod', title: 'RCOD' },
];

export const adminAccountCustomerCreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        code: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.code}
            </span>
          </div>
        ),
        merchantName: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.merchantName}
            </span>
          </div>
        ),
        phoneNumber: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.phoneNumber}
            </span>
          </div>
        ),
        email: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.email}
            </span>
          </div>
        ),
        type: (
          <div
            className={`${
              item?.type === 'Basic' ? 'bg-[#F2C94C]' : 'bg-col_66bb6a'
            } max-w-[4.75em] flex items-center justify-center py-2 rounded-[0.5rem]`}
          >
            <span
              className={`text-col_fff text-txt_087_normal capitalize py-[2px] rounded-[2rem] `}
            >
              {item.type}
            </span>
          </div>
        ),
        status: (
          <div
            className={`${
              item?.status === 'Inactive' ? 'bg-col_e84e40' : 'bg-col_66bb6a'
            } max-w-[4.75em] flex items-center justify-center py-2 rounded-[0.5rem]`}
          >
            <span
              className={`text-col_fff text-txt_087_normal capitalize py-[2px] rounded-[2rem] `}
            >
              {item.status}
            </span>
          </div>
        ),
        discount: (
          <div className="flex items-center">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.discount}
            </span>
          </div>
        ),
        rcod: (
          <div
            className={`${
              item?.rcod === 'Yes' ? 'bg-col_66bb6a' : 'bg-col_e84e40'
            } max-w-[4.75em] flex items-center justify-center py-2 rounded-[0.5rem]`}
          >
            <span
              className={`text-col_fff text-txt_087_normal capitalize py-[2px] rounded-[2rem] `}
            >
              {item.rcod}
            </span>
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const adminDevices_TableHeader = [
  { key: 'name', title: 'NAME' },
  { key: 'serialNumber', title: 'SERIAL NUMBER' },
  { key: 'imei', title: 'IMEI' },
  { key: 'imeiTwo', title: 'IMEI 2' },
  { key: 'handStarp', title: 'HAND STARP' },
  { key: 'useCable', title: 'USE CABLE' },
  { key: 'powerAdapter', title: 'POWER ADAPTER' },
  { key: 'active', title: 'ACTIVE' },
  { key: 'simCardNumber', title: 'SIM CARD NUMBER' },
  { key: 'networkProvider', title: 'NETWORK PROVIDER' },
  { key: 'description', title: 'DESCRIPTION' },
  { key: 'others', title: '' },
];

export const adminDevicesCreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        name: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.name}
            </span>
          </div>
        ),
        serialNumber: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.serialNumber}
            </span>
          </div>
        ),
        imei: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.imei}
            </span>
          </div>
        ),
        imeiTwo: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.imeiTwo}
            </span>
          </div>
        ),
        handStarp: (
          <div
            className={`${
              item?.status === 'No' ? 'bg-col_e84e40' : 'bg-col_66bb6a'
            } max-w-[4.75em] flex items-center justify-center py-2 rounded-[0.5rem]`}
          >
            <span
              className={`text-col_fff text-txt_087_normal capitalize py-[2px] rounded-[2rem] `}
            >
              {item.handStarp}
            </span>
          </div>
        ),
        useCable: (
          <div
            className={`${
              item?.useCable === 'No' ? 'bg-col_e84e40' : 'bg-col_66bb6a'
            } max-w-[4.75em] flex items-center justify-center py-2 rounded-[0.5rem]`}
          >
            <span
              className={`text-col_fff text-txt_087_normal capitalize py-[2px] rounded-[2rem] `}
            >
              {item.useCable}
            </span>
          </div>
        ),
        powerAdapter: (
          <div
            className={`${
              item?.powerAdapter === 'No' ? 'bg-col_e84e40' : 'bg-col_66bb6a'
            } max-w-[4.75em] flex items-center justify-center py-2 rounded-[0.5rem]`}
          >
            <span
              className={`text-col_fff text-txt_087_normal capitalize py-[2px] rounded-[2rem] `}
            >
              {item.powerAdapter}
            </span>
          </div>
        ),
        active: (
          <div
            className={`${
              item?.active === 'Inactive' ? 'bg-col_e84e40' : 'bg-col_66bb6a'
            } max-w-[4.75em] flex items-center justify-center py-2 rounded-[0.5rem]`}
          >
            <span
              className={`text-col_fff text-txt_087_normal capitalize py-[2px] rounded-[2rem] `}
            >
              {item.active}
            </span>
          </div>
        ),
        simCardNumber: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.simCardNumber}
            </span>
          </div>
        ),
        networkProvider: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.networkProvider}
            </span>
          </div>
        ),
        description: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.description}
            </span>
          </div>
        ),
        others: (
          <div className="flex items-center justify-end gap-4 pr-[1rem]">
            <img
              src={search_plus}
              alt=""
              className="cursor-pointer"
              onClick={item.others.viewItem}
            />
            <img src={edit_icon} alt="" className="cursor-pointer" />
            <img
              src={red_delete_icon}
              alt=""
              className="w-6 cursor-pointer opacity-50 hover:opacity-100"
            />
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const adminDeviceManagement_TableHeader = [
  { key: 'device', title: 'DEVICE' },
  { key: 'serialNumber', title: 'SERIAL NUMBER' },
  { key: 'name', title: 'NAME' },
  { key: 'email', title: 'EMAIL' },
  { key: 'phoneNumber', title: 'PHONE NUMBER' },
  { key: 'designation', title: 'DESTINATION' },
  { key: 'location', title: 'LOCATION' },
  { key: 'dataSimcardNumber', title: 'DATA SIM CARD NUMBER' },
  { key: 'status', title: 'STATUS' },
];

export const adminDeviceManagementCreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        device: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.device}
            </span>
          </div>
        ),
        serialNumber: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.serialNumber}
            </span>
          </div>
        ),
        name: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.name}
            </span>
          </div>
        ),
        email: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.email}
            </span>
          </div>
        ),
        phoneNumber: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.phoneNumber}
            </span>
          </div>
        ),
        designation: (
          <div
            className={`${
              item?.designation === 'Basic' ? 'bg-[#F2C94C]' : 'bg-col_66bb6a'
            } max-w-[4.75em] flex items-center justify-center py-2 rounded-[0.5rem]`}
          >
            <span
              className={`text-col_fff text-txt_087_normal capitalize py-[2px] rounded-[2rem] `}
            >
              {item.designation}
            </span>
          </div>
        ),
        location: (
          <div
            className={`${
              item?.location === 'Inactive' ? 'bg-col_e84e40' : 'bg-col_66bb6a'
            } max-w-[4.75em] flex items-center justify-center py-2 rounded-[0.5rem]`}
          >
            <span
              className={`text-col_fff text-txt_087_normal capitalize py-[2px] rounded-[2rem] `}
            >
              {item.location}
            </span>
          </div>
        ),
        dataSimcardNumber: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.dataSimcardNumber}
            </span>
          </div>
        ),
        status: (
          <div
            className={`${
              item?.status === 'Inactive' ? 'bg-col_e84e40' : 'bg-col_66bb6a'
            } max-w-[4.75em] flex items-center justify-center py-2 rounded-[0.5rem]`}
          >
            <span
              className={`text-col_fff text-txt_087_normal capitalize py-[2px] rounded-[2rem] `}
            >
              {item.status}
            </span>
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const adminCaptainBonusByZoneMapping_TableHeader = [
  { key: 'zone', title: 'ZONE' },
  { key: 'bonusAccount', title: 'BONUS ACCOUNT' },
  { key: 'others', title: '' },
];

export const adminCaptainBonusByZoneMappingCreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        zone: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.zone}
            </span>
          </div>
        ),
        bonusAccount: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.bonusAccount}
            </span>
          </div>
        ),
        others: (
          <div className="flex items-center justify-end gap-4 pr-[1rem]">
            <img
              src={search_plus}
              alt=""
              className="cursor-pointer"
              onClick={item.others.viewItem}
            />
            <img src={edit_icon} alt="" className="cursor-pointer" />
            <img
              src={red_delete_icon}
              alt=""
              className="w-6 cursor-pointer opacity-50 hover:opacity-100"
            />
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const adminOptionsAuditTrailLogs_TableHeader = [
  { key: 'user', title: 'USER' },
  { key: 'email', title: 'EMAIL' },
  { key: 'role', title: 'ROLE' },
  { key: 'page', title: 'PAGE' },
  { key: 'action', title: 'ACTION' },
  { key: 'description', title: 'DESCRIPTION' },
  { key: 'date', title: 'DATE' },
];

export const adminOptionsAuditTrailLogsCreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        user: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.user}
            </span>
          </div>
        ),
        email: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.email}
            </span>
          </div>
        ),
        role: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.role}
            </span>
          </div>
        ),
        page: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.page}
            </span>
          </div>
        ),
        action: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.action}
            </span>
          </div>
        ),
        description: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.description}
            </span>
          </div>
        ),
        date: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.date}
            </span>
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const adminPaymentMethod_TableHeader = [
  { key: 'name', title: 'NAME' },
  { key: 'country', title: 'COUNTRY' },
  { key: 'status', title: 'STATUS' },
  { key: 'others', title: '' },
];

export const adminPaymentMethodCreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        name: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.name}
            </span>
          </div>
        ),
        country: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.country}
            </span>
          </div>
        ),
        status: (
          <div
            className={`${
              item?.status === 'Inactive' ? 'bg-col_e84e40' : 'bg-col_66bb6a'
            } max-w-[4.75em] flex items-center justify-center py-2 rounded-[0.5rem]`}
          >
            <span
              className={`text-col_fff text-txt_087_normal capitalize py-[2px] rounded-[2rem] `}
            >
              {item.status}
            </span>
          </div>
        ),
        others: (
          <div className="flex items-center justify-end gap-4 pr-[1rem]">
            <img
              src={search_plus}
              alt=""
              className="cursor-pointer"
              onClick={item.others.viewItem}
            />
            <img src={edit_icon} alt="" className="cursor-pointer" />
            <img
              src={red_delete_icon}
              alt=""
              className="w-6 cursor-pointer opacity-50 hover:opacity-100"
            />
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const adminOptionCaptainTransactions_TableHeader = [
  { key: 'partnerName', title: 'PARTNER NAME' },
  { key: 'code', title: 'CODE' },
  { key: 'email', title: 'EMAIL' },
  { key: 'partnerType', title: 'PARTNER TYPE' },
  { key: 'amount', title: 'AMOUNT' },
  { key: 'others', title: '' },
];

export const adminOptionCaptainTransactionsCreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        partnerName: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.partnerName}
            </span>
          </div>
        ),
        code: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.code}
            </span>
          </div>
        ),
        email: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.email}
            </span>
          </div>
        ),
        partnerType: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.partnerType}
            </span>
          </div>
        ),
        amount: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.amount}
            </span>
          </div>
        ),
        others: (
          <div className="flex items-center justify-end gap-4 pr-[1rem]">
            <img
              src={search_plus}
              alt=""
              className="cursor-pointer"
              onClick={item.others.viewItem}
            />
            <img src={edit_icon} alt="" className="cursor-pointer" />
            <img
              src={red_delete_icon}
              alt=""
              className="w-6 cursor-pointer opacity-50 hover:opacity-100"
            />
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const adminOptionGoFasterReport_TableHeader = [
  { key: 'departure', title: 'DEPARTURE' },
  { key: 'amount', title: 'AMOUNT' },
  { key: 'totalWeight', title: 'TOTAL WEIGHT' },
  { key: 'totalShipment', title: 'TOTAL SHIPMENT' },
  { key: 'others', title: '' },
];

export const adminOptionGoFasterReportCreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        departure: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.departure}
            </span>
          </div>
        ),
        amount: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.amount}
            </span>
          </div>
        ),
        totalWeight: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.totalWeight}
            </span>
          </div>
        ),
        totalShipment: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.totalShipment}
            </span>
          </div>
        ),
        others: (
          <div className="flex items-center justify-end gap-4 pr-[1rem]">
            <img
              src={search_plus}
              alt=""
              className="cursor-pointer"
              onClick={item.others.viewItem}
            />
            <img src={edit_icon} alt="" className="cursor-pointer" />
            <img
              src={red_delete_icon}
              alt=""
              className="w-6 cursor-pointer opacity-50 hover:opacity-100"
            />
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const adminOptionsWallet_TableHeader = [
  { key: 'name', title: 'NAME' },
  { key: 'email', title: 'EMAIL' },
  { key: 'phoneNumber', title: 'PHONE NUMBER' },
  { key: 'balance', title: 'BALANCE' },
  { key: 'type', title: 'TYPE' },
  { key: 'code', title: 'CODE' },
  { key: 'country', title: 'COUNTRY' },
  { key: 'others', title: '' },
];

export const adminOptionsWalletCreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        name: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.name}
            </span>
          </div>
        ),
        email: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.email}
            </span>
          </div>
        ),
        phoneNumber: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.phoneNumber}
            </span>
          </div>
        ),
        balance: (
          <div
            className={`${
              item?.balance === 'Go Standard' ? 'bg-[#F2C94C]' : 'bg-col_66bb6a'
            } flex items-center justify-center py-2  rounded-[0.5rem]`}
          >
            <span
              className={`text-col_fff text-txt_087_normal capitalize px-3 rounded-[2rem] `}
            >
              {item.balance}
            </span>
          </div>
        ),
        type: (
          <div className="flex items-center justify-center py-4">
            <span
              className={`text-txt_087_normal py-2 px-3 text-col_fff bg-col_76818e rounded-[0.5rem] capitalize`}
            >
              {item.type}
            </span>
          </div>
        ),
        code: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.code}
            </span>
          </div>
        ),
        country: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.country}
            </span>
          </div>
        ),
        others: (
          <div className="flex items-center justify-end gap-4 pr-[1rem]">
            <img
              src={search_plus}
              alt=""
              className="cursor-pointer"
              onClick={item.others.viewItem}
            />
            <img src={edit_icon} alt="" className="cursor-pointer" />
            <img
              src={red_delete_icon}
              alt=""
              className="w-6 cursor-pointer opacity-50 hover:opacity-100"
            />
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const adminEnterprisePartners_TableHeader = [
  { key: 'name', title: 'NAME' },
  { key: 'code', title: 'CODE' },
  { key: 'phoneNumber', title: 'PHONE NUMBER' },
  { key: 'others', title: '' },
];

export const adminEnterprisePartnersCreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        name: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.name}
            </span>
          </div>
        ),
        code: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.code}
            </span>
          </div>
        ),
        phoneNumber: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.phoneNumber}
            </span>
          </div>
        ),
        others: (
          <div className="flex items-center justify-end gap-4 pr-[1rem]">
            <img
              src={search_plus}
              alt=""
              className="cursor-pointer"
              onClick={item.others.viewItem}
            />
            <img src={edit_icon} alt="" className="cursor-pointer" />
            <img
              src={red_delete_icon}
              alt=""
              className="w-6 cursor-pointer opacity-50 hover:opacity-100"
            />
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const adminEnterprisePartnersAssigned_TableHeader = [
  { key: 'firstName', title: 'FIRST NAME' },
  { key: 'lastName', title: 'LAST NAME' },
  { key: 'code', title: 'CODE' },
  { key: 'others', title: '' },
];

export const adminEnterprisePartnersAssignedCreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, i: number) => {
      const show = {
        serial: (
          <span className={`text-txt_087_normal text-col_525252`}>
            {i + 1}.
          </span>
        ),
        firstName: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.firstName}
            </span>
          </div>
        ),
        lastName: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.lastName}
            </span>
          </div>
        ),
        code: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.code}
            </span>
          </div>
        ),
        others: (
          <div className="flex justify-end">
            <Button
              text="Remove"
              onClick={item.others.viewItem}
              className={`rounded-[0.25rem] py-3 px-6 flex items-center justify-end text-txt_087_normal text-col_fff bg-col_e84e40 h-[1.625rem]`}
            />
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};

export const adminEnterpriseUnassignedPartners_TableHeader = [
  { key: 'partnerName', title: 'PARTNER NAME' },
  { key: 'code', title: 'CODE' },
  { key: 'others', title: '' },
];

export const adminEnterpriseUnassignedPartnersCreateTableData = (data: any) => {
  const dataToMap = data;
  if (Array.isArray(dataToMap)) {
    const outputArray: any = [];
    dataToMap.map((item: any, _i: number) => {
      const show = {
        partnerName: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.partnerName}
            </span>
          </div>
        ),
        code: (
          <div className="flex items-center ">
            <span className={`text-txt_087_normal text-col_525252 capitalize`}>
              {item.code}
            </span>
          </div>
        ),
        others: (
          <div className="flex items-center justify-end gap-4 pr-[1rem]">
            <div>
              <Button
                onClick={item.others.viewItem}
                text="Add"
                className={`rounded-[0.25rem] py-3 px-6 flex items-center justify-center text-txt_087_normal text-col_fff opacity-50 hover:opacity-100 bg-[#219653] h-[1.625rem]`}
              />
            </div>
          </div>
        ),
      };
      outputArray.push(show);
      return true;
    });
    return outputArray;
  }
};
