import { dropdown_arr_down, slash } from 'assets';
import { useState } from 'react';

interface IPaginationProps {
  perPage?: number;
  setPerPage?: (limit: number) => void;
  page: number;
  changePage?: (limit: number, pageNumber: number) => void;
  pages?: number;
  dataLength?: number;
  handleChangePageNumber?: (pageNumber: number) => void;
}

export const Pagination = ({
  page,
  pages,
  dataLength,
  perPage,
  setPerPage,
  handleChangePageNumber,
  changePage,
}: IPaginationProps) => {
  const [drop, setDrop] = useState(false);

  return (
    <div className="relative md:flex items-center justify-between p-4">
      <div className="flex items-center gap-4">
        <span className="text-txt_087_normal text-col_525252">View</span>
        <PaginationDropdown
          drop={drop}
          setDrop={setDrop}
          showRow={perPage}
          setPerPage={setPerPage}
          perPage={perPage}
          changePage={changePage}
          page={page}
          handleChangePageNumber={
            handleChangePageNumber && handleChangePageNumber
          }
        />
        <span className="text-txt_087_normal text-col_525252">
          entries per page
        </span>
      </div>
      <div className="flex items-center gap-4">
        <span className="text-txt_087_normal text-col_525252">
          Showing {1} to {perPage} of {dataLength} entries
        </span>
        <div className="h-[3rem] p-2 rounded-lg bg-col_fff shadow-header_shadow flex items-center gap-2 box-border">
          <span className="text-txt_087_normal text-col_525252">{page}</span>
          <img src={slash} alt="" />
          <span className="text-txt_087_normal text-col_525252">{pages}</span>
          <img
            src={dropdown_arr_down}
            alt=""
            className="w-4 cursor-pointer transition-transform duration-300"
            style={{ transform: 'rotate(90deg)' }}
            onClick={() => {
              if (page > 1) {
                if (handleChangePageNumber) {
                  handleChangePageNumber(page - 1);
                }
              }
            }}
          />
          <img
            src={dropdown_arr_down}
            alt=""
            className="w-4 cursor-pointer transition-transform duration-300"
            style={{ transform: 'rotate(-90deg)' }}
            onClick={() => {
              if (pages && page < pages) {
                if (handleChangePageNumber) {
                  handleChangePageNumber(page + 1);
                }
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

const PaginationDropdown = ({
  //   drop,
  //   setDrop,
  //   showRow,
  setPerPage,
  perPage,
  //   changePage,
  page,
  handleChangePageNumber,
}: any) => {

  return (
    <div className="">
      {/* <div
        className="h-[2.5rem] w-[4rem] rounded-[0.7rem] flex items-center justify-center gap-2 cursor-pointer bg-col_fff border border-col_fafafa shadow-header_shadow"
        onClick={() => setDrop(!drop)}
      >
        <span className="text-txt_087_normal text-col_525252">{showRow}</span>
        <img
          src={dropdown_arr_down}
          alt=""
          className={`w-[0.8rem] transition-transform duration-200 ${drop ? 'rotate-180' : 'rotate-0'}`}
        />
      </div> */}
      {
        // <div className="absolute top-8 bottom-0  mt-2 w-[4rem] h-fit rounded-[0.7rem] bg-col_fff shadow-header_shadow z-10">
        //   {[5, 10, 20, 30].map((item: number, i: number) => (
        //     <p
        //       key={i}
        //       className="h-[2.5rem] w-[4rem] text-col_525252 text-txt_087_normal flex items-center justify-center cursor-pointer hover:bg-col_f0f0f0"
        //       onClick={() => {
        //         setPerPage(item);
        //         setDrop(false);
        //       }}
        //     >
        //       {item}
        //     </p>
        //   ))}
        // </div>
        <select
          value={perPage}
          onChange={(e) => {
            // changePage(perPage, page);
            setPerPage(Number(e.target.value));
            handleChangePageNumber(page > 1 ? page - 1 : page);
          }}
          className="h-[2.5rem] w-[4rem] px-2 rounded-[0.7rem] bg-col_fff border border-col_fafafa shadow-header_shadow text-txt_087_normal text-col_525252 cursor-pointer"
        >
          {[5, 10, 20, 30].map((item, i) => (
            <option key={i} value={item}>
              {item}
            </option>
          ))}
        </select>
      }
    </div>
  );
};
