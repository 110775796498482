import { view_return_shipment } from 'assets';
import { Header } from 'components/header/Header';
import { MobileNav } from 'components/layout/Layout';
import { RedTitle } from 'components/util-components/RedTitle';
import { Button } from 'components';
import { FormDetailCard } from 'components/util-components/FormDetailCard';
import { useState } from 'react';

type Props = {
  toggleCreateView: () => void;
};

export const CreateIndividualCustomer = ({ toggleCreateView }: Props) => {
  const [email, setEmail] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [country, setCountry] = useState('');
  const [address, setAddress] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [returnOption, setReturnOption] = useState('');

  return (
    <div className="w-full h-full relative border overflow-y-auto">
      <Header
        greenTimerBtn={false}
        announcement={false}
        category="home"
        subCategory="Individual Customer"
        thirdCategory="Create Individual Customer"
        headerTabs={[]}
        headerRedTopBtn={{
          text: 'View Individual Customer',
          prefixIcon: view_return_shipment,
          onClick: toggleCreateView,
        }}
        className=""
      />
      <main className="slide-up-content-reveal page-main-cont tb:mt-[8.5rem] mt-[10rem]">
        <div className="w-full md:w-[35.7rem] mx-auto">
          <RedTitle title="Create New Customer" className="mb-5" />
          <FormDetailCard
            contentArr={[
              {
                type: 'text',
                label: 'first name',
                value: firstName,
                setValue: setFirstName,
                placeholder: '',
                className: 'mb-4',
              },
              {
                type: 'text',
                label: 'last name',
                value: lastName,
                setValue: setLastName,
                placeholder: '',
                className: 'mb-4',
              },
              {
                type: 'radio',
                label: 'select gender',
                value: returnOption,
                setValue: setReturnOption,
                options: ['male', 'female'],
                placeholder: '',
                className: 'my-6',
              },
            ]}
          />
          <div className='h-4' />
          <FormDetailCard
            contentArr={[
              {
                type: 'text',
                label: 'email',
                value: email,
                setValue: setEmail,
                required: true,
                placeholder: 'Enter email',
                className: 'mb-4',
              },
              {
                type: 'text',
                label: 'city',
                value: city,
                setValue: setCity,
                required: true,
                placeholder: 'Enter city',
                className: 'mb-4',
              },
              {
                type: 'dropdown',
                label: 'state',
                value: state,
                setValue: setState,
                required: true,
                placeholder: 'Enter state',
                className: 'mb-4',
              },
              {
                type: 'dropdown',
                label: 'country',
                value: country,
                setValue: setCountry,
                required: true,
                placeholder: '',
                className: 'mb-4',
              },
              {
                type: 'textarea',
                label: 'address',
                value: address,
                setValue: setAddress,
                rows: 3,
                placeholder: '',
                className: 'mb-4',
              },
              {
                type: 'phone',
                label: 'phone number',
                value: phone,
                setValue: setPhone,
                placeholder: '',
                className: 'mb-4',
              },
            ]}
            className=""
          />

          <div className="w-full flex items-center gap-x-2 my-2">
            <Button
              text="Save"
              onClick={(vals) => console.log('vals', vals)}
              className="btn-default py-2 px-2 cursor-pointer text-[0.75rem] bg-col_e84e40 text-col_fff"
            />
            <Button
              text="reset"
              onClick={() => {}}
              className="btn-default py-2 px-2 cursor-pointer text-[0.75rem] bg-col_76818e text-col_fff"
            />
          </div>
        </div>
      </main>
      <MobileNav
        headerNaviagtionBtn={[
          {
            text: 'View Individual Customer',
            type: 'red-btn',
            prefixIcon: view_return_shipment,
            onClick: toggleCreateView,
          },
        ]}
        className=""
      />
    </div>
  );
};
