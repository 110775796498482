import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { createTableHeader } from 'utils/TableHeaders';

type Props = {
  tableHeader: any;
  createTableRow: { [key: string]: string | number | boolean | object }[];
  tableContent?: any;
};

export const useTableSetup = ({ tableHeader, createTableRow }: Props) => {
  const [tableData, setTableData] = useState<{ [key: string]: string | number | boolean | object }[]>([]);
  const { pathname } = useLocation();

  const cols = useMemo(() => createTableHeader(tableHeader), [tableHeader]);

  useEffect(() => {
    // const dataSet: any = createTableRow
    setTableData(createTableRow);
  }, [pathname]);

  return { cols, tableData };
};
