import { success_gif } from "assets"

export const MoveNewManifestModal = () => {
  return (
    <div className="continue-payment lg:w-[25rem] min-h-[15rem] max-h-[20rem] flex flex-col items-center">
      <img src={success_gif} alt="" className='w-[5rem]' />
      <p className='text-txt_15_600 text-col_525252 pt-6 mb-4 text-center'>
        Manifest Changed Successfully!
      </p>
      <p className="text-sm text-col_807e7c">
        Your new manifest number is <span className="font-bold">30009994858590</span>
      </p>
    </div>
  )
}
